<template>
  <card class="p-4" title="Produtos">
    <!-- Modal Delete Product -->
    <div
      class="modal fade"
      id="deleteProductModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteProductModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteProductModalLabel">
              Deletar Produto
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            - Produto será deletado do banco de dados
          </div>
          <div class="modal-footer justify-content-end">
            <button
              type="button"
              class="btn btn-success mr-3"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteProduct()"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Delete Product -->

    <LoadingSpinner :show="loading" />

    <div class="row my-2 justify-content-between align-items-center">
      <div class="col-12 col-md-6">
        <label>Procurar produtos</label>
        <base-input
          v-model="search"
          addonLeftIcon="tim-icons icon-zoom-split"
          placeholder="Ex:. Coca cola"
        ></base-input>
      </div>
      <div class="col-12 col-md-6 text-right">
        <button @click.prevent="createProduct()" class="btn btn-success">
          Criar produto
        </button>
      </div>
    </div>
    <div
      class="text-right cursor-pointer d-inline-block ml-auto mr-2"
      @click="getProducts()"
    >
      <unicon name="sync" fill="#00f2c3" width="16px"></unicon>
    </div>
    <div class="table-responsive">
      <div v-for="product of filteredProducts" :key="product.product_id">
        <table class="table -xl text-center">
          <thead>
            <tr>
              <th scope="col">Imagem do Produto</th>
              <th scope="col">Produto</th>
              <th scope="col">Descrição</th>
              <th scope="col">Editar</th>
              <th scope="col">Deletar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ProductImage :src="product.image" />
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ product.title }}
                </p>
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ product.description }}
                </p>
              </td>
              <td :class="{ 'not-manager': !isManagerAdmin }">
                <router-link :to="`/product/edit/${product.product_id}`">
                  <unicon name="edit" fill="#1d8cf8" width="22px"></unicon>
                </router-link>
              </td>
              <td
                data-toggle="modal"
                data-target="#deleteProductModal"
                @click="deleteProductSelected = product"
              >
                <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProductImage from "../components/ProductImage.vue";
import LoadingSpinner from "../components/Spinner.vue";
import { positive, validationRequire } from "../middleware/validations";
import NotificationTemplate from "./Notifications/NotificationTemplate";

validationRequire();
positive();

export default {
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    ProductImage,
  },
  data() {
    return {
      loading: false,
      search: "",
      products: [],
      company_id: "",
      warehouse_id: "",
      addActions: [
        { label: "ENTRADA NF", value: 1 },
        { label: "ENTRADA DEVOLUCAO VENDA", value: 2 },
        { label: "ENTRADA DE PRODUCAO", value: 3 },
        { label: "ENTRADA BONIFICACAO", value: 4 },
        { label: "ENTRADA DOACAO", value: 5 },
        { label: "ENTRADA OUTRAS", value: 6 },
      ],
      deleteProductSelected: {
        product_id: "",
      },
      addProductSelected: {
        product_id: "",
        amount: 0,
        action: 1,
        expiration: "",
      },
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.getProducts();
  },
  methods: {
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    notifyVue(verticalAlign, horizontalAlign, message, type) {
      this.$notify({
        component: NotificationTemplate,
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type,
        timeout: 0,
      });
    },
    createProduct() {
      this.$router.push({
        name: "createProduct",
      });
    },
    handleEdit(id) {
      this.$router.push({
        name: "product",
        params: { id },
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
    async getProducts() {
      try {
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        this.loading = true;
        const { data } = await this.$http.get("/products", {
          params,
        });
        this.products = data.products;
        localStorage.setItem(
          "@Snackly:products",
          JSON.stringify(data.products)
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async deleteProduct() {
      try {
        this.loading = true;
        await this.$http.delete(
          `/product?product_id=${this.deleteProductSelected.product_id}`
        );
        await this.getProducts();
        this.$toast.success("Produto deletado com Sucesso");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
  computed: {
    isManagerAdmin() {
      return this.$store.getters["manager/isManagerAdmin"];
    },
    filteredProducts() {
      return this.products.filter((product) => {
        return (
          product.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        );
      });
    },
  },
};
</script>

<style lang="css" scoped>
.not-manager {
  filter: brightness(50%);
  pointer-events: none;
}

.details {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

table {
  border-radius: 12px;
  background: transparent;
}

thead th {
  padding: 40px 0;
}

tbody td {
  border-color: rgba(14, 14, 20, 0.329) !important;
  cursor: pointer !important;
  padding: 0;
  width: 160px;
  max-width: 160px;
  word-wrap: break-word;
}

thead th {
  padding: 0;
  width: 160px;
  max-width: 160px;
  color: #ffffff !important;
}

tbody p span {
  font-size: 12px;
}

div.modal-footer {
  justify-content: flex-end;
}
</style>