<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        filename: `${name}_${dateFormatted(new Date(), true)}`,
        margin: [4, 0, 10, 0],
        html2canvas: {
          scale: 2,
        },
      }"
    >
      <div slot="pdf-content" style="padding: 20px">
        <div class="text-center">
          <img
            style="width: 128px"
            src="../../../public/img/favicon.png"
            alt="Snackly Logo"
          />
        </div>
        <h3 class="text-dark font-weight-bold text-center">
          {{ name.toUpperCase() }}
        </h3>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row-reverse;
          "
        >
          <p class="text-dark" style="font-size: 12px">
            {{ user.name }} | {{ user.email }}
          </p>
          <p class="text-dark" style="font-size: 12px">
            Data do inventário {{ dateFormatted(date, true) }}
          </p>
        </div>
        <div style="margin-top: 1rem">
          <table table id="customers">
            <thead>
              <tr>
                <th>Valor total diferença</th>
                <th>Valor unitário</th>
                <th>Diferença</th>
                <th>Quant. Física</th>
                <th>Quant. Sistema</th>
                <th>Nome</th>
              </tr>
            </thead>
            <tbody class="text-dark">
              <tr
                class="avoid-break"
                v-for="(product, idx) in inventoryItems"
                :key="idx"
              >
                <td
                  class="table-td"
                  :class="{
                    negative: Number(product.total_value) < 0,
                  }"
                >
                  {{ toBRL(Math.abs(product.total_value)) }}
                  {{ Number(product.total_value) >= 0 ? "" : "-" }}
                </td>
                <td class="table-td">{{ toBRL(product.unit_value) }}</td>
                <td
                  class="table-td"
                  :class="{
                    negative: Number(product.quantity_difference) < 0,
                  }"
                >
                  {{ Math.abs(Number(product.quantity_difference)) }}
                  {{ Number(product.quantity_difference) >= 0 ? "" : "-" }}
                </td>
                <td class="table-td">{{ product.physical_quantity }}</td>
                <td class="table-td">{{ product.system_quantity }}</td>
                <td>{{ product.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="mt-5" id="table-total">
          <tbody class="text-dark">
            <tr>
              <td
                class="table-td"
                :class="{
                  negative: Number(negativeTotal) < 0,
                }"
              >
                {{ toBRL(Math.abs(Number(negativeTotal))) }}
                {{ Number(negativeTotal) >= 0 ? "" : "-" }}
              </td>
              <td class="table-th">Total déficit</td>
            </tr>
            <tr>
              <td
                class="table-td"
                :class="{
                  negative: Number(positiveTotal) < 0,
                }"
              >
                {{ toBRL(Math.abs(Number(positiveTotal))) }}
                {{ Number(positiveTotal) >= 0 ? "" : "-" }}
              </td>
              <td class="table-th">Total excedente</td>
            </tr>
          </tbody>
        </table>
        <p
          class="text-dark mt-5 text-center font-weight-bold"
          style="font-size: 12px"
        >
          Luttos | {{ name }} {{ dateFormatted(date, true) }}
        </p>
      </div>
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import LoadingSpinner from "../../components/Spinner.vue";
moment.locale("pt-BR");

export default {
  name: "MachineInventoryDiffPDF",
  components: {
    VueHtml2pdf,
    LoadingSpinner,
  },
  mounted() {},
  props: {
    items: { type: Array },
    name: {
      type: String,
      default: "Relatório",
    },
    user: {
      name: {
        type: String,
        email: String,
      },
    },
    date: { type: String },
  },
  data() {
    return {
      loading: false,
      positiveTotal: 0,
      negativeTotal: 0,
    };
  },
  methods: {
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    dateFormatted(date, hasHours = false) {
      return hasHours ? moment(date).format("L LT") : moment(date).format("L");
    },
    onProgress(event) {
      if (event == 100) {
        this.loading = false;
      }
    },
    hasGenerated() {
      this.loading = false;
    },
  },
  computed: {
    inventoryItems() {
      this.positiveTotal = 0;
      this.negativeTotal = 0;
      for (let item of this.items) {
        if (item.total_value < 0) {
          this.negativeTotal += item.total_value;
        } else {
          this.positiveTotal += item.total_value;
        }
      }
      return this.items;
    },
  },
};
</script>

<style lang="css" scoped>
.avoid-break {
  page-break-inside: avoid;
}
.items-total {
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

#customers,
td {
  border: 1px solid #f3f3f3;
}

#table-total {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 50%;
  font-size: 12px;
}

#customers td,
#customers th {
  padding: 4px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #cb523d;
  color: white;
}

.table-th {
  background-color: #cb523d;
  color: white;
  padding: 8px;
  font-weight: 600;
}

.table-td {
  text-align: right;
  font-weight: 600;
  padding: 0 8px;
}

.negative {
  font-weight: 600;
  color: #f95738;
  text-align: right;
}
</style>
