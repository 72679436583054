<template>
  <card type="user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <!-- <a href="#">
        <img class="avatar" src="img/anime6.png" alt="...">
        <h5 class="title">{{ user.fullName }}</h5>
      </a> -->
      <p class="description">
        {{ user.title }}
      </p>
    </div>
    <p></p>
    <p class="card-description">
      {{ user.description }}
    </p>
    <div class="row justify-content-center">
      <div class="col-md-8 mb-3">
        <label for="selectCompanies" class="form-label"
          >Escolha uma Organização <span class="text-danger"></span
        ></label>
        <select
          v-model="selectedCompanyId"
          class="form-control"
          id="selectCompanies"
        >
          <option :selected="!selectedCompanyId" :value="null">
            --- Selecione uma Organização ---
          </option>
          <option
            class="bg-light"
            :value="company.company_id"
            :selected="selectedCompanyId == company.company_id"
            v-for="company in companiesList"
            :key="company.company_id"
          >
            {{ company.fancy_name }}
          </option>
        </select>
      </div>
      <div class="col-md-8 mb-3">
        <label for="selectWarehouses" class="form-label"
          >Escolha um depósito <span class="text-danger"></span
        ></label>
        <select
          v-model="selectedWarehouseId"
          class="form-control"
          id="selectWarehouses"
        >
          <option :selected="!selectedWarehouseId" :value="null">
            --- Selecione um Estoque ---
          </option>
          <option
            class="bg-light"
            :value="warehouse.warehouse_id"
            :selected="selectedWarehouseId == warehouse.warehouse_id"
            v-for="warehouse in warehouseList"
            :key="warehouse.warehouse_id"
          >
            {{ warehouse.warehouse_name }}
          </option>
        </select>
      </div>
    </div>

    <p class="text-center">
      <base-button slot="footer" type="primary" fill @click="handleSave"
        >Salvar</base-button
      >
    </p>
  </card>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedCompanyId: null,
      selectedWarehouseId: null,
      warehouseList: [],
      companiesList: [],
    };
  },
  mounted() {
    const getManagerCompanies = this.getManager().companies;
    this.companiesList = getManagerCompanies;
    const managerCompanyLocalStorage = JSON.parse(
      localStorage.getItem("@Snackly:managerCompany")
    );

    if (managerCompanyLocalStorage) {
      this.selectedCompanyId = managerCompanyLocalStorage.company_id;
      this.selectedWarehouseId = managerCompanyLocalStorage.warehouse_id;
    } else {
      if (getManagerCompanies.companies.length == 1) {
        this.selectedCompanyId = getManagerCompanies.companies[0].company_id;
        if (getManagerCompanies.companies[0].warehouses.length == 1) {
          const warehouseId =
            getManagerCompanies.companies[0].warehouses[0].warehouse_id;
          this.selectedWarehouseId = warehouseId;
          getManagerCompanies.warehouse_id = warehouseId;
          this.setCompany(getManagerCompanies[0]);
        }
      }
    }
  },
  methods: {
    ...mapGetters({
      getManager: "manager/getManager",
    }),
    ...mapMutations({
      setCompany: "company/storeCompany",
    }),
    async handleSave() {
      const getManagerCompanies = this.getManager().companies.find(
        (company) => company.company_id == this.selectedCompanyId
      );
      getManagerCompanies.warehouse_id = this.selectedWarehouseId;
      this.setCompany(getManagerCompanies);
      this.$toast.success("Salvo com sucesso");
    },
  },
  watch: {
    selectedCompanyId: function (newCompanyId) {
      this.warehouseList = this.getManager().companies.find(
        (company) => company.company_id == newCompanyId
      ).warehouses;
    },
  },
};
</script>
<style></style>
