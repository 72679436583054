<template>
  <div class="card p-5">
    <!-- Modal Create Category -->
    <div
      class="modal fade"
      id="modalCreateCategory"
      tabindex="-1"
      aria-labelledby="modalCreateCategoryLabel"
      aria-hidden="true"
    >
      <LoadingSpinner :show="loading" />
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCreateCategoryLabel">
              Criar categoria
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver
              ref="modalCreateCategory"
              v-slot="{ handleSubmit }"
            >
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="handleSubmit(onSubmitCreateCategory)"
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label for="inputName" class="form-label text-dark"
                          >Nome da Categoria
                          <span class="text-danger">(*)</span></label
                        >
                        <input
                          v-model="formCategory.name"
                          type="text"
                          class="form-control text-dark"
                          id="inputName"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Criar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Create Category -->

    <h1 class="text-center">Criar Produto</h1>
    <LoadingSpinner :show="loading" />
    <ValidationObserver ref="createProduct" v-slot="{ handleSubmit }">
      <form
        class="row g-3 justify-content-center"
        @submit.prevent="handleSubmit(onSubmitCreateProduct)"
      >
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider name="Produto" v-slot="{ errors }">
                <label for="inputProductSelect" class="form-label text-dark"
                  >Produto</label
                >
                <multiselect
                  :max-height="150"
                  :maxSelectedLabels="1"
                  id="inputProductSelect"
                  :showLabels="false"
                  v-model="selectedProduct"
                  :options="productsList"
                  placeholder="Nome do produto"
                  label="title"
                  track-by="title"
                >
                  <template #option="slotProps">
                    <div class="flex align-items-center text-dark">
                      <img
                        :alt="slotProps.option.title"
                        :src="slotProps.option.image"
                        class="mr-2"
                        style="width: 18px"
                      />
                      <span>{{ slotProps.option.title }}</span>
                    </div>
                  </template>
                </multiselect>
                <p class="fw-normal text-danger p-relative">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="col-md-12">
              <ValidationProvider
                name="Máquina"
                rules="image"
                ref="provider"
                v-slot="{ errors }"
              >
                <label for="formFile" class="form-label"
                  >Image do produto <span class="text-danger"></span
                ></label>
                <input
                  :disabled="isSelectedProduct"
                  @change="handleFileChange"
                  type="file"
                  name="formFile"
                  class="form-control"
                  id="formFile"
                />
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Nome"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="inputName" class="form-label"
                  >Nome do produto <span class="text-danger">(*)</span></label
                >
                <input
                  :disabled="isSelectedProduct"
                  v-model="formProduct.title"
                  type="text"
                  class="form-control"
                  id="inputName"
                />
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="Descrição" v-slot="{ errors }">
                <label for="inputDescription" class="form-label"
                  >Descrição do produto</label
                >
                <input
                  :disabled="isSelectedProduct"
                  v-model="formProduct.description"
                  type="text"
                  class="form-control"
                  id="inputDescription"
                />
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="Código de Barras" v-slot="{ errors }">
                <label for="inputBarCode" class="form-label"
                  >Código de Barras</label
                >
                <input
                  :disabled="isSelectedProduct"
                  v-model="formProduct.bar_code"
                  type="text"
                  class="form-control"
                  id="inputBarCode"
                />
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <label for="inputAcquisitionPrice" class="form-label"
                >Preço de aquisição do produto
                <span class="text-danger">(*)</span></label
              >
              <money
                :disabled="!isSelectedProduct"
                class="form-control"
                id="inputAcquisitionPrice"
                v-model="acquisition_price"
                v-bind="money"
              ></money>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Categoria"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="inputCategory" class="form-label">Categoria</label>
                <div class="d-flex justify-content-center align-items-center">
                  <select
                    :disabled="isSelectedProduct"
                    v-model="formProduct.category_id"
                    class="form-control"
                    id="inputCategory"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      class="bg-dark"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#modalCreateCategory"
                    class="ml-2 bg-dark border-0"
                  >
                    <unicon name="plus" fill="#00f2c3" width="22px"></unicon>
                  </a>
                </div>
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="Data expiração" v-slot="{ errors }">
                <label for="inputDateExpiration" class="form-label"
                  >Data de Validade do Lote</label
                >
                <input
                  :disabled="!isSelectedProduct"
                  type="date"
                  v-model="expiration"
                  class="form-control"
                  id="inputDateExpiration"
                />
                <p class="fw-normal text-danger p-relative">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Disponibilidade"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="inputAvailability" class="form-label"
                  >Quantidade que deseja adicionar</label
                >
                <input
                  :disabled="!isSelectedProduct"
                  type="number"
                  min="0"
                  v-model="quantity"
                  class="form-control"
                  id="inputAvailability"
                />
                <p class="fw-normal text-danger p-relative">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="NCM"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <label for="inputNCM" class="form-label"
                  >Código NCM <span class="text-danger">(*)</span></label
                >
                <input
                  :disabled="isSelectedProduct"
                  v-model="formProduct.cod_ncm"
                  maxlength="8"
                  type="text"
                  class="form-control"
                  id="inputDescription"
                />
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Unidade Comercial"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="inputUnidComercial" class="form-label"
                  >Unid Comercial (UN / KG / L)
                  <span class="text-danger">(*)</span></label
                >
                <select
                  :disabled="isSelectedProduct"
                  v-model="formProduct.commercial_unit"
                  class="form-control"
                  id="inputUnidComercial"
                >
                  <option class="bg-dark" selected value="UN">UN</option>
                  <option class="bg-dark" value="L">L</option>
                  <option class="bg-dark" value="KG">KG</option>
                </select>
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Unidade Tributável"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="inputUnidTributavel" class="form-label"
                  >Unidade Tributável (UN / KG / L)
                  <span class="text-danger">(*)</span></label
                >
                <select
                  :disabled="isSelectedProduct"
                  v-model="formProduct.tax_unit"
                  class="form-control"
                  id="inputUnidTributavel"
                >
                  <option class="bg-dark" selected value="UN">UN</option>
                  <option class="bg-dark" value="L">L</option>
                  <option class="bg-dark" value="KG">KG</option>
                </select>
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="CFOP"
                rules="required|numeric"
                slot="{ errors }"
              >
                <label for="inputCFOP" class="form-label"
                  >CFOP <span class="text-danger">(*)</span></label
                >
                <select
                  :disabled="isSelectedProduct"
                  v-model="formProduct.cfop"
                  class="form-control"
                  id="inputCFOP"
                >
                  <option class="bg-dark" value="5101">
                    5.101 – Venda de produção do estabelecimento;
                  </option>
                  <option class="bg-dark" value="5102">
                    5.102 – Venda de mercadoria de terceiros;
                  </option>
                  <option class="bg-dark" value="5103">
                    5.103 – Venda de produção do estabelecimento efetuada fora
                    do estabelecimento;
                  </option>
                  <option class="bg-dark" value="5104">
                    5.104 – Venda de mercadoria adquirida ou recebida de
                    terceiros, efetuada fora do estabelecimento;
                  </option>
                  <option class="bg-dark" value="5115">
                    5.115 – Venda de mercadoria adquirida ou recebida de
                    terceiros, recebida anteriormente em consignação mercantil;
                  </option>
                  <option class="bg-dark" value="5405">
                    5.405 – Venda de mercadoria adquirida ou recebida de
                    terceiros em operação com mercadoria sujeita ao regime de
                    substituição tributária, na condição de contribuinte
                    substituído;
                  </option>
                  <option class="bg-dark" value="5656">
                    5.656 – Venda de combustível ou lubrificante de terceiros,
                    destinados a consumidor final;
                  </option>
                  <option class="bg-dark" value="5667">
                    5.667 – Venda de combustível ou lubrificante a consumidor ou
                    usuário final estabelecido em outra UF;
                  </option>
                  <option class="bg-dark" value="5933">
                    5.933 – Prestação de serviço tributado pelo ISSQN*
                  </option>
                </select>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="ICMS Origem"
                rules="required"
                slot="{ errors }"
              >
                <label for="inputICMSOrigem" class="form-label"
                  >ICMS Origem <span class="text-danger">(*)</span></label
                >
                <select
                  :disabled="isSelectedProduct"
                  v-model="formProduct.icms_origin"
                  class="form-control"
                  id="inputICMSOrigem"
                >
                  <option class="bg-dark" value="0">0 - nacional.</option>
                  <option class="bg-dark" value="1">
                    1 - estrangeira (importação direta).
                  </option>
                  <option class="bg-dark" value="2">
                    2 - estrangeira (adquirida no mercado interno).
                  </option>
                  <option class="bg-dark" value="3">
                    3 - nacional com mais de 40% de conteúdo estrangeiro.
                  </option>
                  <option class="bg-dark" value="4">
                    4 - nacional produzida através de processos produtivos
                    básicos.
                  </option>
                  <option class="bg-dark" value="5">
                    5 - nacional com menos de 40% de conteúdo estrangeiro.
                  </option>
                  <option class="bg-dark" value="6">
                    6 - estrangeira (importação direta) sem produto nacional
                    similar.
                  </option>
                  <option class="bg-dark" value="7">
                    7 - estrangeira (adquirida no mercado interno) sem produto
                    nacional similar.
                  </option>
                </select>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="ICMS Mod Base Calculo"
                rules="required"
                slot="{ errors }"
              >
                <label for="inputICMSModBaseCalc" class="form-label"
                  >ICMS Modalidade Base Calculo
                  <span class="text-danger">(*)</span></label
                >
                <select
                  :disabled="isSelectedProduct"
                  v-model="formProduct.icms_mod_rate_base"
                  class="form-control"
                  id="inputICMSModBaseCalc"
                >
                  <option class="bg-dark" value="0">
                    0 – margem de valor agregado (%).
                  </option>
                  <option class="bg-dark" value="1">1 – pauta (valor).</option>
                  <option class="bg-dark" value="2">
                    2 – preço tabelado máximo (valor).
                  </option>
                  <option class="bg-dark" value="3">
                    3 – valor da operação.
                  </option>
                </select>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="ICMS Aliquota"
                rules="numeric|max_value:100"
                v-slot="{ errors }"
              >
                <label for="inputICMSAliquota" class="form-label"
                  >ICMS Aliquota %</label
                >
                <input
                  :disabled="isSelectedProduct"
                  v-model="formProduct.icms_rate"
                  type="text"
                  maxlength="3"
                  class="form-control"
                  id="inputICMSAliquota"
                />
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider
                name="Produto de estante"
                rules="required"
                slot="{ errors }"
              >
                <label for="inputProductType" class="form-label mr-2"
                  >Produto de estante</label
                >
                <input
                  :disabled="isSelectedProduct"
                  type="checkbox"
                  id="inputProductType"
                  v-model="formProduct.shelf"
                />
              </ValidationProvider>
            </div>

            <div class="col-12 d-flex justify-content-end">
              <router-link to="/products" class="btn btn-secondary"
                >Voltar</router-link
              >
              <button class="btn btn-primary ml-3" type="submit">
                Cadastrar Produto
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Money } from "v-money";
import Multiselect from "vue-multiselect";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  alphaNum,
  maxValue,
  numericField,
  positive,
  validationImage,
  validationRequire,
} from "../middleware/validations";
import LoadingSpinner from "./Spinner.vue";
validationRequire();
validationImage();
alphaNum();
numericField();
positive();
maxValue();

export default {
  name: "FormProduct",
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    Money,
  },
  data() {
    return {
      loading: false,
      isSelectedProduct: false,
      products: [],
      categories: [],
      selectedProduct: null,
      formCategory: {
        name: "",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      formProduct: {
        file: null,
        image: null,
        title: "",
        description: "",
        bar_code: "",
        cod_ncm: "",
        commercial_unit: "",
        tax_unit: "",
        cfop: "",
        icms_origin: "",
        icms_rate: 0,
        icms_mod_rate_base: "",
        shelf: false,
        category_id: "",
      },
      expiration: null,
      quantity: 0,
      acquisition_price: 0,
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    if (!company_id || !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    const initialLoading = async () => {
      await this.getCategories();
      await this.getProducts();
    };
    initialLoading();
  },
  methods: {
    async getProducts() {
      try {
        this.loading = true;
        const params = {
          warehouse_id: this.warehouse_id,
          company_id: this.company_id,
        };
        const { data } = await this.$http.get("/products", {
          params,
        });
        this.products = data.products;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    modalState(id, state) {
      $(`#${id}`).modal(state);
    },

    async onSubmitCreateCategory() {
      try {
        this.loading = true;
        if (!this.formCategory.name) return;
        const { data } = await this.$http.post("/categories", {
          name: this.formCategory.name,
        });
        await this.getCategories();
        this.formProduct.category_id = data.category.id;
        this.formCategory.name = "";
        this.$refs.modalCreateCategory.reset();
        this.modalState("modalCreateCategory", "hide");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getCategories() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/categories");
        let categories = data.categories;
        this.categories = categories;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getProducts() {
      try {
        const company_id =
          this.$store.getters["company/getManagerCompany"].company_id;
        const warehouse_id =
          this.$store.getters["company/getManagerCompany"].warehouse_id;
        if (!company_id) return;
        if (!warehouse_id) return;

        this.loading = true;
        const { data } = await this.$http.get("/products", {
          params: { company_id, warehouse_id },
        });
        let products = data.products;
        localStorage.setItem("@Snackly:products", JSON.stringify(products));

        this.products = products;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async registerProduct() {
      let formData = new FormData();
      this.formProduct.shelf = this.formProduct.shelf ? 1 : 0;
      if (this.formProduct.file) {
        formData.append(
          "image",
          this.formProduct.file,
          this.formProduct.file.name
        );
      } else {
        formData.append("image", this.formProduct.image);
      }
      formData.append("product_id", this.product_id);
      formData.append("title", this.formProduct.title);
      formData.append("description", this.formProduct.description);
      formData.append("bar_code", this.formProduct.bar_code);
      formData.append("created_at", new Date().toISOString());
      formData.append("cod_ncm", this.formProduct.cod_ncm);
      formData.append("commercial_unit", this.formProduct.commercial_unit);
      formData.append("tax_unit", this.formProduct.tax_unit);
      formData.append("cfop", this.formProduct.cfop);
      formData.append("icms_origin", this.formProduct.icms_origin);
      formData.append("icms_rate", this.formProduct.icms_rate);
      formData.append(
        "icms_mod_rate_base",
        this.formProduct.icms_mod_rate_base
      );
      formData.append("company_id", this.company_id);
      formData.append("warehouse_id", this.warehouse_id);
      formData.append("shelf", this.formProduct.shelf);
      formData.append("category_id", this.formProduct.category_id);
      formData.append("expiration", this.expiration);
      formData.append("quantity", this.quantity);
      formData.append("acquisition_price", this.acquisition_price * 100);
      const response = await this.$http.post("/product", formData);
      return response;
    },
    async handleFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e);
      if (valid) {
        const file = e.target.files[0];
        this.formProduct.file = file;
      }
    },
    async onSubmitCreateProduct() {
      try {
        this.loading = true;
        const response = await this.registerProduct();
        this.$toast.success("Ótimo! Seu produto foi criado com sucesso!");
        this.cleanFields();
        this.loading = false;
        this.$router.push({ name: "products" });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    cleanFields() {
      this.isSelectedProduct = false;
      this.formProduct.file = null;
      this.formProduct.image = null;
      this.formProduct.title = "";
      this.formProduct.description = "";
      this.formProduct.bar_code = "";
      this.formProduct.data = "";
      this.formProduct.cod_ncm = "";
      this.formProduct.commercial_unit = "";
      this.formProduct.tax_unit = "";
      this.formProduct.cfop = "";
      this.formProduct.icms_origin = "";
      this.formProduct.icms_rate = null;
      this.formProduct.icms_mod_rate_base = "";
      this.formProduct.shelf = false;
      this.formProduct.category_id = "";
      this.product_id = null;
      this.expiration = null;
      this.quantity = 0;
      this.acquisition_price = 0;
    },
  },
  watch: {
    selectedProduct: function () {
      if (!this.selectedProduct) {
        this.cleanFields();
        return;
      }
      this.isSelectedProduct = true;
      this.product_id = this.selectedProduct.product_id;
      this.formProduct.title = this.selectedProduct.title;
      this.formProduct.image = this.selectedProduct.image;
      this.formProduct.description = this.selectedProduct.description;
      this.formProduct.bar_code = this.selectedProduct.bar_code;
      this.formProduct.data = this.selectedProduct.description;
      this.formProduct.cod_ncm = this.selectedProduct.cod_ncm;
      this.formProduct.commercial_unit = this.selectedProduct.commercial_unit;
      this.formProduct.tax_unit = this.selectedProduct.tax_unit;
      this.formProduct.cfop = this.selectedProduct.cfop;
      this.formProduct.icms_origin = this.selectedProduct.icms_origin;
      this.formProduct.icms_rate = this.selectedProduct.icms_rate;
      this.formProduct.icms_mod_rate_base =
        this.selectedProduct.icms_mod_rate_base;
      this.formProduct.shelf = this.selectedProduct.shelf ? true : false;
      this.formProduct.category_id = this.selectedProduct.category_id;
    },
  },
  computed: {
    productsList() {
      return this.products;
    },
  },
};
</script>

<style lang="css">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #093aec;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #75f100;
}

#inputLocation,
#inputState {
  border-color: #2b3553;
  border-radius: 0.4285rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  transition: 260ms ease-in;
}

#inputLocation,
#inputState:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#inputDescription {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  transition: 260ms ease-in;
}

#inputDescription:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>