<template>
  <card class="card" title="Inventários dos estoques">
    <LoadingSpinner :show="loading" />
    <div class="card-body">
      <router-link class="btn" to="/create/stock/inventory">
        Fazer inventário
      </router-link>
      <div class="text-right" :class="{ 'transition-animation': isRefreshing }">
        <button class="bg-transparent" style="border: none; cursor: pointer">
          <unicon
            @click="refreshStockInventory()"
            name="sync"
            fill="#00f2c3"
            width="16px"
          ></unicon>
        </button>
      </div>
      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th scope="col">Id do inventário</th>
              <th scope="col">Depósito</th>
              <th scope="col">Data do inventário</th>
              <th scope="col">Inventário</th>
              <th scope="col">Usuário</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col">Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="inventory in stockInventories"
              :key="inventory.inventory_id"
            >
              <td>
                <p class="font-weight-bold">#{{ inventory.inventory_id }}</p>
              </td>
              <td>
                <p class="font-weight-bold">
                  {{ inventory.warehouse_id }}
                </p>
              </td>
              <td>
                <p class="font-weight-bold">
                  {{ dateFormatted(inventory.date) }}
                </p>
              </td>
              <td>
                <router-link
                  class="font-weight-bold"
                  :to="`/stock/inventory/${inventory.inventory_id}`"
                  >Acessar inventário</router-link
                >
              </td>
              <td>
                <p class="font-weight-bold">{{ inventory.user.name }}</p>
              </td>
              <td>
                <p class="font-weight-bold">{{ inventory.user.email }}</p>
              </td>
              <td>
                <p class="font-weight-bold">
                  {{ INVENTORY_STATUS[inventory.status] }}
                </p>
              </td>
              <td>
                <router-link
                  class="font-weight-bold text-primary"
                  :class="{
                    disabled:
                      INVENTORY_STATUS[inventory.status] ==
                      INVENTORY_STATUS.PROCESSED,
                  }"
                  :to="`/edit/stock/inventory/${inventory.inventory_id}`"
                  >Editar
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card>
</template>
<script>
import { InventoryStatus } from "@/services/InventoryStatus";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import LoadingSpinner from "../../components/Spinner.vue";
moment.locale("pt-BR");

export default {
  name: "StockInventories",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      isRefreshing: false,
      warehouse_id: "",
      company_id: "",
      stockInventories: [],
      INVENTORY_STATUS: InventoryStatus,
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.getStockInventories();
  },
  methods: {
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    async refreshStockInventory() {
      this.isRefreshing = true;
      await this.getStockInventories();
    },
    async getStockInventories() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        const { data } = await this.$http.get("/stock/inventories", {
          params,
        });
        this.stockInventories = data.inventory;
        console.log(this.stockInventories);
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>

<style lang="css" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
