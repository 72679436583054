<template>
  <div
    class="row justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="col-12 mb-5">
      <div class="row justify-content-center">
        <div class="col-6">
          <h1 class="title text-center">Selecione a sua organização</h1>
          <form
            class="row g-3 justify-content-center"
            @submit.prevent="onSubmitSelecteCompany"
          >
            <div class="row justify-content-center">
              <div class="col-md-8 mb-3">
                <label for="selectCompanies" class="form-label text-light h4"
                  >Escolha uma Organização
                  <span class="text-danger">*</span></label
                >
                <select
                  v-model="selectedCompanyId"
                  class="form-control"
                  id="selectCompanies"
                >
                  <option :selected="!selectedCompanyId" :value="null">
                    --- Selecione uma Organização ---
                  </option>
                  <option
                    class="bg-light"
                    :value="company.company_id"
                    :selected="selectedCompanyId"
                    v-for="company in companiesList"
                    :key="company.company_id"
                  >
                    {{ company.fancy_name }}
                  </option>
                </select>
              </div>
              <div class="col-md-8 mb-3">
                <label for="selectWarehouses" class="form-label text-light h4"
                  >Escolha um depósito <span class="text-danger">*</span></label
                >
                <select
                  v-model="selectedWarehouseId"
                  class="form-control"
                  id="selectWarehouses"
                >
                  <option
                    class="text-light"
                    :selected="!selectedWarehouseId"
                    :value="null"
                  >
                    --- Selecione um Estoque ---
                  </option>
                  <option
                    class="bg-light text-dark"
                    :value="warehouse.warehouse_id"
                    :selected="selectedWarehouseId"
                    v-for="warehouse in warehouseList"
                    :key="warehouse.warehouse_id"
                  >
                    {{ warehouse.warehouse_name }}
                  </option>
                </select>
              </div>
            </div>
            <button type="submit" class="btn btn-primary btn-">Salvar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import LoadingSpinner from "../Spinner.vue";
export default {
  name: "CompanyConfigForm",
  components: {
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      loading: false,
      selectedCompanyId: null,
      selectedWarehouseId: null,
      warehouseList: [],
      companiesList: [],
    };
  },
  mounted() {
    const getManagerCompanies = this.getManager().companies;
    this.companiesList = getManagerCompanies;
    const managerCompanyLocalStorage = JSON.parse(
      localStorage.getItem("@Snackly:managerCompany")
    );
    if (managerCompanyLocalStorage) {
      this.selectedCompanyId = managerCompanyLocalStorage.company_id;
      this.selectedWarehouseId = managerCompanyLocalStorage.warehouse_id;
    } else {
      if (getManagerCompanies.length == 1) {
        this.selectedCompanyId = getManagerCompanies[0].company_id;
        if (getManagerCompanies[0].warehouses.length == 1) {
          const warehouseId = getManagerCompanies[0].warehouses[0].warehouse_id;
          this.selectedWarehouseId = warehouseId;
          getManagerCompanies[0].warehouse_id = warehouseId;
          this.setCompany(getManagerCompanies[0]);
          this.$emit("manager-selected");
        }
      }
    }
  },
  methods: {
    ...mapGetters({
      getManager: "manager/getManager",
    }),
    ...mapMutations({
      setCompany: "company/storeCompany",
    }),
    async onSubmitSelecteCompany() {
      const getManagerCompanies = this.getManager().companies.find(
        (company) => company.company_id == this.selectedCompanyId
      );
      getManagerCompanies.warehouse_id = this.selectedWarehouseId;
      this.setCompany(getManagerCompanies);
      this.$toast.success("Salvo com sucesso");
      this.$emit("manager-selected");
    },
  },
  watch: {
    selectedCompanyId: function (newCompanyId) {
      this.warehouseList = this.getManager().companies.find(
        (company) => company.company_id == newCompanyId
      ).warehouses;
    },
  },
};
</script>
<style>
</style>