var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"modalInsertProduct","tabindex":"-1","aria-labelledby":"modalInsertProductLabel","aria-hidden":"true"}},[_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"modalInsertProductLabel"}},[_vm._v(" Adicionar Produto na Máquina ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"close"},on:{"click":_vm.cleanFields}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"modalInsertProduct",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"row g-3 justify-content-center",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitAddProductToMachine)}}},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputSelectedProduct"}},[_vm._v("Produto")]),_c('multiselect',{attrs:{"max-height":150,"maxSelectedLabels":1,"id":"inputSelectedProduct","showLabels":false,"options":_vm.multiSelectStockProducts,"custom-label":_vm.titleWithExpiration,"placeholder":"Digite aqui o nome do produto que deseja adicionar","label":"title","track-by":"title"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"flex align-items-center text-dark"},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"18px"},attrs:{"alt":slotProps.option.title,"src":slotProps.option.image}}),_c('span',[_vm._v(_vm._s(slotProps.option.title)+" | ")]),_c('span',[_vm._v("Validade "),_c('strong',{class:_vm.isValidDate(slotProps.option.expiration),staticStyle:{"display":"inline-block","padding":"4px","border-radius":"4px"}},[_vm._v("("+_vm._s(_vm.dateFormatted(slotProps.option.expiration))+")")])]),_c('span',[_vm._v(" Quant. Estq "+_vm._s(slotProps.option.amount))])])]}}],null,true),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Nome do Produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputTitle"}},[_vm._v("Nome do Produto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formProduct.title),expression:"formProduct.title"}],staticClass:"form-control text-dark",attrs:{"disabled":_vm.hasProductOnMachine,"name":"city","type":"text","id":"inputTitle"},domProps:{"value":(_vm.formProduct.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formProduct, "title", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Descrição do produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputDescription"}},[_vm._v("Descrição do produto")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formProduct.description),expression:"formProduct.description"}],staticClass:"form-control text-dark",attrs:{"disabled":_vm.hasProductOnMachine,"name":"city","type":"text","id":"inputTitle"},domProps:{"value":(_vm.formProduct.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formProduct, "description", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Preço de venda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputUnitPrice"}},[_vm._v("Preço de venda")]),_c('money',_vm._b({staticClass:"form-control text-dark",attrs:{"disabled":_vm.hasProductOnMachine,"id":"inputUnitPrice"},model:{value:(_vm.formProduct.price),callback:function ($$v) {_vm.$set(_vm.formProduct, "price", $$v)},expression:"formProduct.price"}},'money',_vm.money,false)),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Desconto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputDiscount"}},[_vm._v("Desconto")]),_c('money',_vm._b({staticClass:"form-control text-dark",attrs:{"disabled":_vm.hasProductOnMachine,"id":"inputDiscount"},model:{value:(_vm.formProduct.discount),callback:function ($$v) {_vm.$set(_vm.formProduct, "discount", $$v)},expression:"formProduct.discount"}},'money',_vm.money,false)),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Disponibilidade","rules":'required|numeric|min_value:1|max_value:' +
                      _vm.productAmountAvailability},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputAvailability"}},[_vm._v("Quantidade "),_c('span',{staticClass:"badge badge-default"},[_vm._v(_vm._s(_vm.formProduct.amount ? _vm.formProduct.amount : 0)+" / "+_vm._s(_vm.productAmountAvailability ? _vm.productAmountAvailability : 0))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formProduct.amount),expression:"formProduct.amount"}],staticClass:"form-control text-dark",attrs:{"type":"text","id":"inputAvailability"},domProps:{"value":(_vm.formProduct.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formProduct, "amount", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row py-4"},[_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-secondary mr-3",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.cleanFields}},[_vm._v(" Fechar ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Adicionar ")])])])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }