<template>
    <div class="container" >
      <LoadingSpinner :show="loading" />
      <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-12 mb-5">
          <div class="row justify-content-center">
            <div class="col-12 mb-5 text-center">
              <img src="../../../public/img/icon.png" style="width: 220px;" alt="Mercatto Logo">
            </div>
            <div class="col-6">
              <h1>Criar conta</h1>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(handleCreateAccount)">
                  <div class="form-group">
                    <ValidationProvider name="Email" rules="required|email" ref="provider" v-slot="{ errors }">
                      <label for="InputEmail">Email</label>
                      <input type="email" v-model="loginData.email" class="form-control" id="InputEmail">
                      <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider name="Nome" rules="required" ref="provider" v-slot="{ errors }">
                      <label for="InputName">Nome</label>
                      <input type="text" v-model="loginData.name" class="form-control" id="InputName">
                      <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider name="Número" rules="required|min_value:15" v-slot="{ errors }">
                      <label for="InputPhoneNumber">Número Telefone</label>
                      <the-mask type="text" placeholder="(00) 00000-0000" :mask="['(##) #####-####']" minlength="15" v-model="loginData.phone_number" class="form-control" id="InputPhoneNumber" />
                      <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                      <ValidationProvider name="Senha" rules="required|password_verify" v-slot="{ errors }">
                        <label for="InputPasswordOne">Senha</label>
                        <input type="password" v-model="loginData.password" class="form-control" id="InputPasswordOne">
                        <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
                      </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <ValidationProvider name="Confirmar senha" rules="required|password_verify" v-slot="{ errors }">
                          <label for="InputPasswordTwo">Confirmar senha</label>
                          <input type="password" v-model="loginData.confirmed_password" class="form-control" id="InputPasswordTwo">
                          <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <p v-if="passwordError" class="fw-normal text-danger p-relative">As senhas devem ser iguais</p>
                  <div class="row mt-3">
                    <div class="col-12 text-right">
                      <button type="submit" class="btn btn-primary">Criar conta</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import LoadingSpinner from '@/components/Spinner.vue';
import { emailValidation, maxValue, minData, minValue, passwordValidation, validationRequire } from '@/middleware/validations';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TheMask } from 'vue-the-mask';
validationRequire();
emailValidation();
maxValue();
minValue();
minData();
passwordValidation()

export default {
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    TheMask
  },
  data() {
    return {
      loading: false,
      token: '',
      passwordError: false,
      loginData: {
        email: '',
        name: '',
        password: '',
        confirmed_password: '',
        phone_number: ''
      }
    }
  },
  methods: {
    handleMatchPassword() {
      if (this.loginData.password != this.loginData.confirmed_password) {
        this.passwordError = true;
      } else {
        this.passwordError = false;
      }
    },
    async createManager(params) {
      await this.$http.post(`manager/token/${this.token}`, params);
    },
    async handleCreateAccount() {
      try {
        this.$router.push({ name: 'confirmSignUp' })
        this.loading = true;
        this.handleMatchPassword();
        if (this.passwordError) {
          this.loading = false;
          return;
        }
        let phone_number = `+55${this.loginData.phone_number}`
        const params = { ...this.loginData, phone_number }
        await this.createManager(params);
        this.$toast.success('Conta criada com sucesso! Código de verificação enviado para o seu email.')
        this.loading = false;
        this.$router.push({ name: 'confirmSignUp' })
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
  },
  mounted() {
    this.token = this.$router.history.current.params.token
  }
}
</script>
  
<style></style>