import { extend } from "vee-validate";
import {
  alpha_num,
  email,
  image,
  max_value,
  min,
  min_value,
  numeric,
  required,
} from "vee-validate/dist/rules";

export const passwordValidation = () =>
  extend("password_verify", {
    params: ["target"],
    message: (field) => {
      return `1- Deve conter números; 2- Deve conter no mínimo uma letra maíuscula; 3- Deve conter no mínimo uma letra menúscula; Deve conter o mínimo de 8 caracteres`;
    },
    validate: (value) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value),
  });

export const emailValidation = () =>
  extend("email", {
    ...email,
    message: "{_field_} não é um email válido",
  });

export const validationRequire = () =>
  extend("required", {
    ...required,
    message: "Campo obrigatório",
  });

export const validationImage = () =>
  extend("image", {
    ...image,
  });

export const alphaNum = () =>
  extend("alpha_num", {
    ...alpha_num,
    message: "{_field_} deve apenas conter apenas caracteres alpha-numericos",
  });

export const numericField = () =>
  extend("numeric", {
    ...numeric,
    message: "{_field_} deve apenas conter números",
  });

export const positive = () =>
  extend("positive", (value) => {
    if (value >= 0) {
      return true;
    }
    return "{_field_} deve ser um número positivo";
  });

export const maxValue = () =>
  extend("max_value", {
    ...max_value,
    message: "{_field_} deve ter o valor máximo de {max} ou menos",
  });

export const minValue = () =>
  extend("min_value", {
    ...min_value,
    message: "{_field_} deve ter o valor mínimo de {min}",
  });

export const minData = () =>
  extend("min", {
    ...min,
    message: "{_field_} deve ter o valor mínimo de {length}",
  });
