<template>
  <card class="card" title="Máquinas">
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Deletar Máquina</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            - Não terá mais acesso a máquina no seu gerenciador
          </div>
          <div class="modal-footer justify-content-end">
            <button
              type="button"
              class="btn btn-success mr-3"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="handleDelete()"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>

    <LoadingSpinner :show="loading" />
    <div class="row">
      <div class="col-md-12 text-right">
        <button @click.prevent="createMachine()" class="btn btn-success">
          Criar máquina
        </button>
      </div>
    </div>
    <div class="row my-2 justify-content-start">
      <div class="col-md-4 text-left">
        <label>Buscar máquina</label>
        <base-input
          v-model="search"
          addonLeftIcon="tim-icons icon-zoom-split"
          placeholder="Ex:. Aprimore"
        ></base-input>
      </div>
    </div>

    <div class="row">
      <div
        class="text-right cursor-pointer d-inline-block ml-auto mr-2"
        @click="refreshMachines()"
      >
        <unicon name="sync" fill="#00f2c3" width="16px"></unicon>
      </div>
      <div
        class="card"
        v-for="machine of filteredMachines"
        :key="machine.machine_id"
      >
        <table class="table table-responsive-xl text-center">
          <thead>
            <tr>
              <th scope="col">Imagem da Máquina</th>
              <th scope="col">Endereço</th>
              <th scope="col">Hardware</th>
              <th scope="col">Descrição</th>
              <th scope="col">Data de Lançamento</th>
              <th scope="col">Capacidade</th>
              <th scope="col">Modelo</th>
              <th scope="col">Editar</th>
              <th scope="col">Máquina</th>
              <th scope="col">Deletar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`">
                  <div class="picture-container mx-auto">
                    <img class="picture-product" :src="machine.image" />
                  </div>
                </router-link>
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`">
                  <p class="text-white mx-2 font-weight-bold py-4">
                    {{ machine.location.address }},
                    {{ machine.location.number }} -
                    {{ machine.location.district }},
                    {{ machine.location.city }} - {{ machine.location.state }}
                  </p>
                </router-link>
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><p class="text-white mx-2 font-weight-bold py-4">
                    {{ machine.hardware }}
                  </p></router-link
                >
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><p class="text-white mx-2 font-weight-bold py-4">
                    <span class="badge badge-danger d-block">{{
                      machine.description
                    }}</span>
                  </p></router-link
                >
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><p class="text-white mx-2 font-weight-bold py-4">
                    <span class="badge badge-primary d-block">{{
                      dateFormatted(machine.launch_date)
                    }}</span>
                  </p></router-link
                >
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><p class="text-white mx-2 font-weight-bold py-4">
                    <span class="badge badge-default d-block"
                      >{{ machine.capacity }}L</span
                    >
                  </p></router-link
                >
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><p class="text-white mx-2 font-weight-bold py-4">
                    <span class="badge badge-default d-block">{{
                      machine.model
                    }}</span>
                  </p></router-link
                >
              </td>
              <td :class="{ 'not-manager': !isManagerAdmin }">
                <router-link :to="`/machine/edit/${machine.machine_id}`"
                  ><unicon name="edit" fill="#00f2c3" width="22px"></unicon
                ></router-link>
              </td>
              <td>
                <router-link :to="`/machine/${machine.machine_id}`"
                  ><unicon
                    name="shopping-basket"
                    fill="#1d8cf8"
                    width="22px"
                  ></unicon
                ></router-link>
              </td>
              <td
                data-toggle="modal"
                data-target="#exampleModal"
                @click="deleteMachineSelected = machine.machine_id"
                :class="{ 'not-manager': !isManagerAdmin }"
              >
                <unicon name="trash-alt" fill="#fd5d93" width="22px"></unicon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card>
</template>
<script>
import moment from "moment";
import LoadingSpinner from "../components/Spinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      search: "",
      loading: false,
      machinesLocations: [],
      deleteMachineSelected: "",
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const selectedCompany =
      this.$store.getters["company/getManagerCompany"].company_id;
    if (!selectedCompany) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
    } else {
      this.getMachines();
    }
  },
  methods: {
    dateFormatted(date) {
      moment.locale("pt-BR");
      return moment(date).format("L");
    },
    refreshMachines() {
      this.getMachines();
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });

        this.machinesLocations = data.machines;
        localStorage.setItem(
          "@Snackly:machines",
          JSON.stringify(data.machines)
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleDelete() {
      try {
        this.loading = true;
        await this.$http.delete(`/machine/${this.deleteMachineSelected}`);
        await this.getMachines();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    createMachine() {
      this.$router.push({
        name: "createMachine",
      });
    },
    openMachine(id) {
      this.$router.push({
        name: "machine",
        params: { id },
        query: this.$route.query,
        hash: this.$route.hash,
      });
    },
  },
  computed: {
    isManagerAdmin() {
      return this.$store.getters["manager/isManagerAdmin"];
    },
    filteredMachines() {
      return this.machinesLocations.filter((machine) => {
        return (
          machine.description
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1 ||
          machine.hardware.toLowerCase().indexOf(this.search.toLowerCase()) !=
            -1
        );
      });
    },
  },
};
</script>

<style lang="css" scoped>
.visibility-hidden {
  visibility: hidden;
}

.not-manager {
  filter: brightness(50%);
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  padding: 40px !important;
}

table {
  border-radius: 12px;
  background: transparent;
}

thead th {
  padding: 40px 0;
}

tbody td {
  border-color: rgba(14, 14, 20, 0.329) mportant;
  cursor: pointer !important;
}

thead th {
  color: #ffffff !important;
}

tbody p span {
  font-size: 12px;
}

.picture-container {
  text-align: center;
  width: 75px;
  height: 75px;
  min-width: 75px;
  text-align: center;
  line-height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

.picture-container .picture-product {
  max-width: 70px;
}

a.create-item {
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75rem;
  padding: 0.75rem 1.2rem;
  border-radius: 28px;
  transition: 200ms ease-in;
}

a .unicon.plus-circle {
  background: white;
  vertical-align: middle;
  border-radius: 50%;
  margin-left: 0.5rem;
  line-height: 0.88rem;
}

a.btn-create {
  width: 12rem;
}

a.btn-create:hover {
  transform: translateY(0);
}
</style>
