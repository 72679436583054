<template>
  <div class="card">
    <div class="row">
      <h1>Id do Produto {{productId}}</h1>
    </div>
  </div>
</template>
<script>
import api from "../services/api"

export default {
  data() {
    return {
      productId: []
    }
  },
  methods: {
    
  },
  mounted() {
    this.productId = this.$route.params.id
  }
};
</script>
<style>
</style>
