<template>
  <card class="p-4" title="Relatório de vendas">
    <LoadingSpinner :show="loading" />
    <div class="row align-center justify-content-between">
      <div class="col-12 col-sm-6">
        <label for="inputMachine" class="form-label text-light"
          >Filtrar por máquina</label
        >
        <select
          v-model="selectedMachine"
          class="form-control"
          id="inputMachine"
          @change="getSalesReport()"
        >
          <option class="bg-dark" :selected="!selectedMachine" :value="null">
            --- Todas as máquinas ---
          </option>
          <option
            v-for="machine in machines"
            :value="machine.machine_id"
            :key="machine.machine_id"
            class="bg-dark"
          >
            {{ machine.description }}
          </option>
        </select>
      </div>
      <div class="col-12 col-sm-6">
        <label for="inputProduct" class="form-label text-light"
          >Filtrar por produto</label
        >
        <select
          v-model="selectedProduct"
          class="form-control"
          id="inputProduct"
        >
          <option class="bg-dark" :selected="!selectedProduct" :value="null">
            --- Todas os produtos ---
          </option>
          <option
            v-for="(product, idx) in getProducts"
            :value="product.product_id"
            :key="idx"
            class="bg-dark"
          >
            {{ product.name }}
          </option>
        </select>
      </div>
    </div>
    <DatePicker
      @receiveStartDate="receiveStartDate"
      @receiveEndDate="receiveEndDate"
    />
    <ReportPDF
      :chartData="getChartData"
      :endDate="endDate"
      :startDate="startDate"
      :logList="salesReport"
      :total="total"
    />
    <div class="table-responsive">
      <SalesReportTable title="Relatório de Vendas" :logList="salesReport" />
      <h3 class="text-right">Valor total {{ toBRL(total / 100) }}</h3>
    </div>
  </card>
</template>
<script>
import moment from "moment";
import DatePicker from "../components/DatePicker.vue";
import ReportPDF from "../components/SalesReport/ReportPDF.vue";
import SalesReportTable from "../components/SalesReport/SalesReportTable.vue";
import LoadingSpinner from "../components/Spinner.vue";
import TabNavigation from "../components/TabNavigation.vue";
moment.locale("pt-BR");

export default {
  name: "Report",
  components: {
    LoadingSpinner,
    TabNavigation,
    SalesReportTable,
    ReportPDF,
    DatePicker,
  },
  data() {
    return {
      total: 0,
      startDate: "",
      endDate: "",
      selectedMachine: "",
      selectedProduct: "",
      loading: false,
      machineStockLog: [],
      sales: [],
      machines: [],
      products: [],
      warehouse_id: "",
      company_id: "",
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.initialLoading();
  },
  methods: {
    receiveStartDate(date) {
      this.startDate = date;
    },
    receiveEndDate(date) {
      this.endDate = date;
    },
    resizeChartData(data, size = 10) {
      const newChartData = [];
      while (data.categories.length) {
        newChartData.push({
          categories: data.categories.splice(0, size),
          series: [
            { name: "Quantidade", data: data.series[0].data.splice(0, size) },
          ],
          unitPrices: { data: data.unitPrices.data.splice(0, size) },
        });
      }
      return newChartData;
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    async handleDateChanges() {
      await this.getSalesReport();
    },
    async initialLoading() {
      await this.getSalesReport();
      await this.getMachines();
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id: this.company_id,
          },
        });

        this.machines = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getSalesReport() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.selectedMachine,
          company_id: this.company_id,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("sales/report", {
          params,
        });
        const { sales } = response.data;
        this.sales = sales;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    filteredSalesReport() {
      const salesList = this.sales;
      if (!this.selectedProduct) return salesList;
      return salesList.filter((sale) => {
        return sale.product.id == this.selectedProduct;
      });
    },
  },
  computed: {
    getProducts() {
      const items = new Map();
      this.sales.forEach((sale) => {
        items.set(sale.product.id, {
          product_id: sale.product.id,
          name: sale.product.name,
        });
      });
      return Array.from(items.values());
    },
    getChartData() {
      const salesItems = [];
      this.salesReport.forEach((sale) => {
        const getData = salesItems.find(
          (item) => item.name == sale.product.name
        );
        if (getData) {
          getData.quantity += sale.quantity;
          getData.price = sale.price;
          getData.acquisition_price = sale.acquisition_price;
        } else {
          salesItems.push({
            name: sale.product.name,
            quantity: sale.quantity,
            price: sale.price,
            acquisition_price: sale.acquisition_price,
          });
        }
      });
      salesItems.sort((itemA, itemB) => itemB.quantity - itemA.quantity);
      const chartData = {
        categories: [],
        series: [{ name: "Quantidade", data: [] }],
        unitPrices: {
          data: [],
        },
      };
      for (let product of salesItems) {
        const { quantity, price } = product;
        const [productFirstName, productLastName] = product.name.split(" ");
        let name = `${this.toBRL(price / 100)} | ${productFirstName}`;
        if (productLastName) {
          name += ` ${productLastName}`;
        }
        chartData.categories.push(`${name}`);
        chartData.series[0].data.push(quantity);
        chartData.unitPrices.data.push(price);
      }
      return [chartData];
    },
    salesReport() {
      let total = 0;
      this.sales.forEach((sale) => {
        total += (sale.price - sale.discount) * sale.quantity;
      });
      this.total = total;
      return this.filteredSalesReport();
    },
  },
  watch: {
    startDate() {
      this.handleDateChanges();
    },
    endDate() {
      this.handleDateChanges();
    },
  },
};
</script>
<style lang="css" scoped>
</style>
