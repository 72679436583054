var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"modalAddProduct","tabindex":"-1","aria-labelledby":"modalAddProductLabel","aria-hidden":"true"}},[_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"modalAddProductLabel"}},[_vm._v(" Adicionar produto no estoque da máquina ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.cleanFields}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('Picture',{attrs:{"imageSrc":_vm.productImage}}),_c('p',{staticClass:"title-4"},[_vm._v(" Quantidade no estoque: "+_vm._s(_vm.productAmountAvailability)+" ")]),_c('ValidationObserver',{ref:"modalAddProduct",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"row g-3 justify-content-center",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitAddProductInventory)}}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputSelectedProduct"}},[_vm._v("Produto")]),_c('multiselect',{attrs:{"max-height":150,"maxSelectedLabels":1,"id":"inputSelectedProduct","showLabels":false,"options":_vm.multiSelectStockProducts,"custom-label":_vm.titleWithExpiration,"placeholder":"Digite aqui o nome do produto que deseja adicionar","label":"title","track-by":"title"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"flex align-items-center text-dark"},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"18px"},attrs:{"alt":slotProps.option.title,"src":slotProps.option.image}}),_c('span',[_vm._v(_vm._s(slotProps.option.title)+" | ")]),_c('span',[_vm._v("Validade "),_c('strong',{class:_vm.isValidDate(slotProps.option.expiration),staticStyle:{"display":"inline-block","padding":"4px","border-radius":"4px"}},[_vm._v("("+_vm._s(_vm.dateFormatted(slotProps.option.expiration))+")")])]),_c('span',[_vm._v(" Quant. Estq "+_vm._s(slotProps.option.amount))])])]}}],null,true),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Disponibilidade","rules":'required|numeric|min_value:1|max_value:' +
                      _vm.productAmountAvailability},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputAvailability"}},[_vm._v("Quantidade "),_c('span',{staticClass:"badge badge-default"},[_vm._v(_vm._s(_vm.amount ? _vm.amount : 0)+" / "+_vm._s(_vm.productAmountAvailability))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticClass:"form-control text-dark",attrs:{"type":"number","min":"0","id":"inputAvailability"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Unidade Comercial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{staticClass:"form-label text-dark",attrs:{"for":"inputProductMoviment"}},[_vm._v("Tipo Movimentação")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.action),expression:"action"}],staticClass:"form-control text-dark",attrs:{"id":"inputProductMoviment"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.action=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.addActions),function(action){return _c('option',{key:action.value,domProps:{"value":action.value}},[_vm._v(" "+_vm._s(action.label)+" ")])}),0),_c('span',{staticClass:"fw-normal text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row row py-4"},[_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-secondary mr-3",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.cleanFields}},[_vm._v(" Fechar ")]),_c('button',{staticClass:"btn btn-primary btn-",attrs:{"type":"submit"}},[_vm._v(" Adicionar ")])])])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }