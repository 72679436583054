import moment from "moment";
moment.locale("pt-BR");

class CSVReportDiff {
  constructor(items, name) {
    this.items = items;
    this.name = `${name}_${this.dateFormatted(new Date(), true)}.csv`;
  }

  execute() {
    return this.exportDataToCsv();
  }

  exportDataToCsv() {
    const productsCSV = this.items.map((product) => {
      let newProduct = null;
      if (product.expiration) {
        newProduct = {
          Produto: product.title,
          Validade: this.dateFormatted(product.expiration),
          "Quant. Sistema": product.system_quantity,
          "Quant. Física": product.physical_quantity,
          Diferenca: product.quantity_difference,
          "V. Unit.": product.unit_value / 100,
          "V. Total Diferença.": product.total_value / 100,
        };
      } else {
        newProduct = {
          Produto: product.title,
          "Quant. Sistema": product.system_quantity,
          "Quant. Física": product.physical_quantity,
          Diferenca: product.quantity_difference,
          "V. Unit.": product.unit_value / 100,
          "V. Total Diferença.": product.total_value / 100,
        };
      }
      return newProduct;
    });
    const csvContent = this.convertToCSV(productsCSV);
    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8",
    });
    return URL.createObjectURL(blob);
  }

  convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const rows = data.map((obj) => {
      return Object.values(obj);
    });
    const headerRow = headers.join(",");
    const csvRows = [headerRow, ...rows.map((row) => row.join(","))];
    return csvRows.join("\n");
  }

  dateFormatted(date, hasHours = false) {
    return hasHours ? moment(date).format("L LT") : moment(date).format("L");
  }
}

export default CSVReportDiff;
