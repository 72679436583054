<template>
  <div class="card">
    <LoadingSpinner :show="loading" />
    <div v-if="!loading" class="row">
      <div class="col-10 mx-auto">
        <router-link to="/machines">
          <base-button round icon class="mt-4" type="secondary">
            <unicon name="angle-left" fill="#1d8cf8"></unicon>
          </base-button>
        </router-link>
        <div class="row my-5">
          <div class="col-12 col-md-6 container-image-machine">
            <img
              class="image-machine"
              :src="machine.image"
              alt="Machine Image"
            />
          </div>
          <div class="col-12 col-md-6 pl-5 mt-4">
            <div class="row">
              <div class="col-12">
                <h1 class="display-1 border-bottom pb-2">
                  {{ machine.description }}
                </h1>
                <h3
                  class="font-weight-light"
                  v-if="machine.location != undefined"
                >
                  {{ machine.location.address }},
                  {{ machine.location.number }} -
                  {{ machine.location.district }}, {{ machine.location.city }}
                </h3>
                <p>Capacidade: {{ machine.capacity }}L</p>
                <p>
                  Data de Lançamento: {{ dateFormatted(machine.launch_date) }}
                </p>
                <p>Modelo: {{ machine.model }}</p>
              </div>
            </div>
          </div>
        </div>

        <MachineStockProductsList :machineId="machine.machine_id" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import MachineStockProductsList from "../components/MachineStockProductsList.vue";
import LoadingSpinner from "../components/Spinner.vue";

moment.locale("pt-BR");

export default {
  components: {
    MachineStockProductsList,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      warehouse_id: "",
      company_id: "",
      machine: {
        machine_id: "",
        launch_date: "",
        capacity: "",
        description: "",
        hardaware: "",
        image: "",
      },
    };
  },
  mounted() {
    this.machine.machine_id = this.$route.params.id;
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.initialLoading();
  },
  methods: {
    async initialLoading() {
      try {
        this.loading = true;
        await this.getMachine();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    async getMachine() {
      const machines = JSON.parse(localStorage.getItem("@Snackly:machines"));
      if (!machines) {
        throw Error("Máquina não encontrada");
      }
      let getMachine = machines.find(
        (machine) => machine.machine_id == this.machine.machine_id
      );
      this.machine = getMachine;
    },
  },
};
</script>
<style lang="css" scoped>
.container-image-machine {
  position: relative;
  text-align: center;
  background: white;
  border-radius: 12px;
  max-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.container-image-machine .image-machine {
  z-index: 10;
  width: 360px;
  transition: scale 450ms ease-in;
}

.container-image-machine:hover .image-machine {
  scale: 1.2;
}

.container-image-machine .model-label {
  z-index: 15;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 200ms ease-in;
}

.container-image-machine .capacity-label {
  z-index: 15;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(2%);
  transition: all 200ms ease-in;
}

.container-image-machine:hover .capacity-label {
  opacity: 1;
}

.container-image-machine:hover .capacity-label h3 {
  transform: scale(1);
}

.capacity-label h3 {
  box-sizing: border-box;
  transform: scale(0);
  width: 300px;
  height: 50px;
  margin: 0;
  font-weight: bold;
  background: #42b883;
  font-size: 1.2rem;
  line-height: 50px;
  transition: transform 200ms ease-in;
}

.container-image-machine .launch-date-label {
  z-index: 15;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: transform 200ms ease-in;
}

.container-image-machine:hover .launch-date-label {
  opacity: 1;
}

.container-image-machine:hover .launch-date-label h3 {
  transform: scale(1);
}

.launch-date-label h3 {
  box-sizing: border-box;
  transform: scale(0);
  width: 340px;
  height: 50px;
  margin: 0;
  font-weight: bold;
  background: #1d8cf8;
  font-size: 1.2rem;
  line-height: 50px;
  border-top-right-radius: 1rem;
  transition: transform 200ms ease-in;
}
</style>
