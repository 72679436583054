<template>
  <div class="container">
    <LoadingSpinner :show="loading" />
    <div
      class="row justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div class="col-12 mb-5">
        <div class="row justify-content-center">
          <div class="col-12 mb-5 text-center">
            <img
              src="../../../public/img/icon.png"
              style="width: 220px"
              alt="Mercatto Logo"
            />
          </div>
          <div class="col-6">
            <form @submit.prevent="submit()">
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  v-model="loginInfo.email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Senha</label>
                <input
                  type="password"
                  v-model="loginInfo.password"
                  class="form-control"
                  id="exampleInputPassword1"
                />
              </div>
              <button type="submit" class="btn btn-primary">Entrar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Spinner.vue";
import { mapActions } from "vuex";

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      loginInfo: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({ LogIn: "auth/LogIn" }),
    async getManager() {
      try {
        this.loading = true;
        let manager = JSON.parse(localStorage.getItem("@Snackly:Manager"));
        if (!manager) {
          const { data } = await this.$http.get("/manager");
          const { managers } = data;
          manager = managers;
        }
        this.$store.commit("manager/storeManager", manager);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async submit() {
      try {
        this.loading = true;
        await this.LogIn({
          email: this.loginInfo.email,
          password: this.loginInfo.password,
        });
        await this.getManager();
        this.$router.push({ name: "managerConfig" });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          let message = "";
          switch (err.message) {
            case "Missing required parameter USERNAME":
              message = "Parâmetro Usuário obrigatório";
              break;
            case "Missing required parameter PASSWORD":
              message = "Parâmetro Senha obrigatório";
              break;
            case "Incorrect username or password.":
              message = "Usuário ou senha incorretos";
              break;
            case "Password attempts exceeded":
              message = "Excedido tentativas de senha";
            default:
              message = "Contate nosso suporte suporte@mercatto.com";
              break;
          }
          this.$toast.error("Usuário ou senha incorretos");
        }
      }
    },
  },
};
</script>

<style></style>