var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-5"},[_c('h1',{staticClass:"text-center"},[_vm._v("Criar Máquina")]),_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"row g-3 justify-content-center",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Localização")])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Cep","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputPostalCode"}},[_vm._v("Cep "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('TheMask',{staticClass:"form-control",attrs:{"id":"inputPostalCode","mask":['#####-###'],"type":"text"},model:{value:(_vm.formLocation.postalCode),callback:function ($$v) {_vm.$set(_vm.formLocation, "postalCode", $$v)},expression:"formLocation.postalCode"}}),_c('span',{staticClass:"fw-normal text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"inputState"}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.state),expression:"formLocation.state"}],staticClass:"form-select w-100 d-block my-2 py-2",attrs:{"id":"inputState"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formLocation, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"bg-dark",attrs:{"selected":"","disabled":""}},[_vm._v("Selecione um Estado "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_vm._l((_vm.formLocation.states),function(name,value){return _c('option',{key:value,staticClass:"bg-dark",domProps:{"value":value}},[_vm._v(_vm._s(name)+" - "+_vm._s(value))])})],2),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputCity"}},[_vm._v("Cidade "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.city),expression:"formLocation.city"}],staticClass:"form-control",attrs:{"name":"city","type":"text","id":"inputCity"},domProps:{"value":(_vm.formLocation.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation, "city", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Endereço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputAddress"}},[_vm._v("Endereço "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.address),expression:"formLocation.address"}],staticClass:"form-control",attrs:{"type":"text","id":"inputAddress"},domProps:{"value":(_vm.formLocation.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation, "address", $event.target.value)}}}),_c('span',{staticClass:"fw-normal text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Bairro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputDistrict"}},[_vm._v("Bairro "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.district),expression:"formLocation.district"}],staticClass:"form-control",attrs:{"type":"text","id":"inputDistrict"},domProps:{"value":(_vm.formLocation.district)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation, "district", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Número","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputNumber"}},[_vm._v("Número")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.number),expression:"formLocation.number"}],staticClass:"form-control",attrs:{"type":"text","id":"inputNumber"},domProps:{"value":(_vm.formLocation.number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation, "number", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputComplement"}},[_vm._v("Complemento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.complement),expression:"formLocation.complement"}],staticClass:"form-control",attrs:{"type":"text","maxlength":"75","id":"inputComplement"},domProps:{"value":(_vm.formLocation.complement)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation, "complement", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Latitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputLatitude"}},[_vm._v("Latitude "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.coordinates.latitude),expression:"formLocation.coordinates.latitude"}],staticClass:"form-control",attrs:{"type":"text","id":"inputLatitude"},domProps:{"value":(_vm.formLocation.coordinates.latitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation.coordinates, "latitude", $event.target.value)}}}),_c('span',{staticClass:"fw-normal text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Longitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputLongitude"}},[_vm._v("Longitude "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formLocation.coordinates.longitude),expression:"formLocation.coordinates.longitude"}],staticClass:"form-control",attrs:{"maxlength":"10","type":"text","id":"inputLongitude"},domProps:{"value":(_vm.formLocation.coordinates.longitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formLocation.coordinates, "longitude", $event.target.value)}}}),_c('span',{staticClass:"fw-normal text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Máquina")])]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Hardware"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputHardware"}},[_vm._v("Hardware")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMachine.hardware),expression:"formMachine.hardware"}],staticClass:"form-control",attrs:{"type":"text","id":"inputHardware"},domProps:{"value":(_vm.formMachine.hardware)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formMachine, "hardware", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{ref:"provider",attrs:{"name":"Máquina","rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"formFile"}},[_vm._v("Imagem da Máquina "),_c('span',{staticClass:"text-danger"})]),_c('input',{staticClass:"form-control",attrs:{"type":"file","name":"formFile","id":"formFile"},on:{"change":_vm.handleFileChange}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Capacidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputCapacity"}},[_vm._v("Capacidade "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMachine.capacity),expression:"formMachine.capacity"}],staticClass:"form-control",attrs:{"type":"number","id":"inputCapacity"},domProps:{"value":(_vm.formMachine.capacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formMachine, "capacity", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Data de lançamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputLaunchDate"}},[_vm._v("Data de Lançamento "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMachine.launchDate),expression:"formMachine.launchDate"}],staticClass:"form-control",attrs:{"type":"Date","id":"inputLaunchDate"},domProps:{"value":(_vm.formMachine.launchDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formMachine, "launchDate", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Modelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputModel"}},[_vm._v("Modelo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMachine.model),expression:"formMachine.model"}],staticClass:"form-control",attrs:{"type":"text","id":"inputModel"},domProps:{"value":(_vm.formMachine.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formMachine, "model", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputDescription"}},[_vm._v("Descrição")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formMachine.description),expression:"formMachine.description"}],staticClass:"form-control px-2",attrs:{"maxlength":"255","id":"inputDescription","rows":"3"},domProps:{"value":(_vm.formMachine.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formMachine, "description", $event.target.value)}}})]),_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":"/machines"}},[_vm._v("Voltar")]),_c('button',{staticClass:"btn btn-primary  ml-3",attrs:{"type":"submit"}},[_vm._v("Cadastrar Máquina")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }