<template>
  <div class="row">
    <LoadingSpinner :show="loading" />
    <ModalUpdateProduct
      :productImage="productImage"
      :machineId="machineId"
      :formProductUpdate="formProductUpdate"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalRemoveProduct
      :productImage="productImage"
      :machineId="machineId"
      :formRemoveProduct="formRemoveProduct"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalAddProduct
      :productImage="productImage"
      :machineId="machineId"
      :formAddProduct="formAddProduct"
      :stockProducts="products"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalAddProductOnMachine
      :productImage="productImage"
      :machineId="machineId"
      :machineProducts="machineProducts"
      :multiSelectStockProducts="multiSelectStockProducts"
      @refresh-page="receiveEmitRefreshPage"
    />
    <div class="col-12 mx-auto">
      <TabNavigation
        :mode="historyMode"
        :labels="{ first: 'Estoque', second: 'Vendas' }"
        @toggle-mode="receiveEmitToggleMode"
      />
      <div class="row my-2 justify-content-between align-items-center">
        <div class="col-md-4 text-left">
          <label>Procurar produtos</label>
          <base-input
            v-model="search"
            addonLeftIcon="tim-icons icon-zoom-split"
            placeholder="Ex:. Coca cola"
          ></base-input>
        </div>
        <button
          data-toggle="modal"
          data-target="#modalInsertProduct"
          class="btn btn-success"
        >
          Adicionar produto do estoque
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table text-center" v-show="!historyMode">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Produto</th>
            <th scope="col">Descrição</th>
            <th scope="col">Quant Máquina</th>
            <th scope="col">Preço de venda</th>
            <th scope="col">Adicionar</th>
            <th scope="col">Remover</th>
            <th>Editar</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="product of filteredMachineProducts"
            :key="product.expiration + product.product_id"
          >
            <td>
              <div class="picture-container mx-auto">
                <img class="picture-product" :src="product.image" />
              </div>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.title }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.description }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-info d-block py-2">{{
                  product.amount
                }}</span>
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-default d-block py-2">{{
                  toBRL(product.unit_price / 100)
                }}</span>
              </p>
            </td>
            <td
              @click="handleModalAddProduct(product)"
              data-toggle="modal"
              data-target="#modalAddProduct"
            >
              <p>
                <unicon name="plus" fill="#00f2c3" width="22px"></unicon>
              </p>
            </td>
            <td
              @click="handleModalRemoveProduct(product)"
              data-toggle="modal"
              data-target="#modalRemoveProduct"
            >
              <p>
                <unicon name="minus" fill="#fd5d93" width="22px"></unicon>
              </p>
            </td>
            <td
              @click="handleModalUpdateData(product)"
              data-toggle="modal"
              data-target="#modalUpdateProduct"
            >
              <p>
                <unicon name="edit" fill="#1d8cf8" width="22px"></unicon>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SaleTableList
      v-show="historyMode"
      :machineId="machineId"
      :search="search"
    />
  </div>
</template>

<script>
import moment from "moment";
import ModalAddProduct from "./Machine/Modal/ModalAddProduct.vue";
import ModalAddProductOnMachine from "./Machine/Modal/ModalAddProductOnMachine.vue";
import ModalRemoveProduct from "./Machine/Modal/ModalRemoveProduct.vue";
import ModalUpdateProduct from "./Machine/Modal/ModalUpdateProduct.vue";
import SaleTableList from "./SaleTableList.vue";
import LoadingSpinner from "./Spinner.vue";
import TabNavigation from "./TabNavigation.vue";

moment.locale("pt-BR");

export default {
  name: "MachineStockProductsList",
  components: {
    ModalUpdateProduct,
    ModalRemoveProduct,
    ModalAddProduct,
    ModalAddProductOnMachine,
    TabNavigation,
    LoadingSpinner,
    SaleTableList,
  },
  props: {
    machineId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      productImage: "",
      machineProducts: [],
      productAmountAvailability: 0,
      products: [],
      sales: [],
      search: "",
      historyMode: false,
      warehouse_id: null,
      company_id: null,
      formAddProduct: {
        product_id: "",
      },
      formRemoveProduct: {
        product_id: "",
      },
      formProductUpdate: {
        product_id: "",
        title: "",
        description: "",
        price: 0,
        discount: 0,
      },
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.warehouse_id = warehouse_id;
    this.company_id = company_id;
    this.initialLoading();
  },
  methods: {
    async initialLoading() {
      try {
        this.loading = true;
        await this.getProducts();
        await this.getMachineProducts();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    handleModalAddProduct({ product_id, expiration, image }) {
      this.getStockProductQuantity(product_id, expiration);
      this.formAddProduct.product_id = product_id;
      this.productImage = image;
    },
    handleModalRemoveProduct({ product_id, image }) {
      this.formRemoveProduct.product_id = product_id;
      this.productImage = image;
    },
    handleModalUpdateData({
      product_id,
      title,
      description,
      unit_price,
      discount,
      image,
    }) {
      this.formProductUpdate.product_id = product_id;
      this.formProductUpdate.title = title;
      this.formProductUpdate.description = description;
      this.formProductUpdate.price = unit_price / 100;
      this.formProductUpdate.discount = discount / 100;
      this.productImage = image;
    },
    receiveEmitToggleMode(mode) {
      this.historyMode = mode;
    },
    receiveEmitRefreshPage() {
      this.initialLoading();
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    toBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    getStockProductQuantity(productId) {
      this.productAmountAvailability = 0;
      const productStockFilter = this.products.filter(
        (productStock) => productStock.product_id == productId
      );
      let totalQuantity = 0;
      productStockFilter[0].stock.forEach((stock) => {
        totalQuantity += stock.amount;
      });
      this.productAmountAvailability = totalQuantity;
    },
    async getProducts() {
      const params = {
        company_id: this.company_id,
        warehouse_id: this.warehouse_id,
      };
      const { data } = await this.$http.get("/products/stock", {
        params,
      });
      const { products } = await data;
      this.products = products;
    },
    async getMachineProducts() {
      const params = {
        machine_id: this.machineId,
        company_id: this.company_id,
      };
      const { data } = await this.$http.get("/machine/products", {
        params,
      });
      const { machineProducts } = data;
      if (machineProducts) {
        this.machineProducts = machineProducts.map((product) => ({
          ...product,
          unit_price: product.unit_price,
        }));
      } else {
        this.machineProducts = [];
      }
    },
  },
  computed: {
    multiSelectStockProducts() {
      let newProducts = [];
      this.products.forEach((product) => {
        for (const productStock in product.stock) {
          newProducts.push({
            ...product.stock[productStock],
            title: product.title,
            description: product.description,
            image: product.image,
            discount: product.discount,
          });
        }
      });
      return newProducts;
    },
    filteredMachineProducts() {
      return this.machineProducts.filter((product) => {
        return (
          product.title.toLowerCase().indexOf(this.search.toLowerCase()) !=
            -1 && product.amount > 0
        );
      });
    },
  },
};
</script>

<style scoped>
.buttons-group {
  margin-top: 1.4rem;
}

div img {
  width: 420px;
}

.btn-create {
  justify-content: center;
}

.btn-create > i {
  line-height: 18px;
}

input[type="checkbox"] {
  margin-left: 0.8751rem;
}
.picture-container {
  text-align: center;
  width: 75px;
  height: 75px;
  min-width: 75px;
  text-align: center;
  line-height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

.picture-container .picture-product {
  max-width: 55px;
}

thead tr th {
  color: #fff !important;
}

td[data-target="#modalInsertProduct"],
td[data-target="#modalUpdateProduct"],
td[data-target="#modalAddProduct"],
td[data-target="#modalRemoveProduct"] {
  cursor: pointer;
}
</style>
