var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('div',{staticClass:"row justify-content-center align-items-center",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"col-12 mb-5"},[_c('div',{staticClass:"row justify-content-center"},[_vm._m(0),_c('div',{staticClass:"col-6"},[_c('h1',[_vm._v("Criar conta")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleCreateAccount)}}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{ref:"provider",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"InputEmail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.email),expression:"loginData.email"}],staticClass:"form-control",attrs:{"type":"email","id":"InputEmail"},domProps:{"value":(_vm.loginData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData, "email", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{ref:"provider",attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"InputName"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.name),expression:"loginData.name"}],staticClass:"form-control",attrs:{"type":"text","id":"InputName"},domProps:{"value":(_vm.loginData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData, "name", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Número","rules":"required|min_value:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"InputPhoneNumber"}},[_vm._v("Número Telefone")]),_c('the-mask',{staticClass:"form-control",attrs:{"type":"text","placeholder":"(00) 00000-0000","mask":['(##) #####-####'],"minlength":"15","id":"InputPhoneNumber"},model:{value:(_vm.loginData.phone_number),callback:function ($$v) {_vm.$set(_vm.loginData, "phone_number", $$v)},expression:"loginData.phone_number"}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Senha","rules":"required|password_verify"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"InputPasswordOne"}},[_vm._v("Senha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.password),expression:"loginData.password"}],staticClass:"form-control",attrs:{"type":"password","id":"InputPasswordOne"},domProps:{"value":(_vm.loginData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData, "password", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Confirmar senha","rules":"required|password_verify"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"InputPasswordTwo"}},[_vm._v("Confirmar senha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.confirmed_password),expression:"loginData.confirmed_password"}],staticClass:"form-control",attrs:{"type":"password","id":"InputPasswordTwo"},domProps:{"value":(_vm.loginData.confirmed_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginData, "confirmed_password", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),(_vm.passwordError)?_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v("As senhas devem ser iguais")]):_vm._e(),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Criar conta")])])])])]}}])})],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mb-5 text-center"},[_c('img',{staticStyle:{"width":"220px"},attrs:{"src":require("../../../public/img/icon.png"),"alt":"Mercatto Logo"}})])}]

export { render, staticRenderFns }