<template>
  <div :style="{ width: size + 'px', height: size + 'px' }">
    <img
      :src="
        src
          ? src
          : 'https://snackly-images.s3.amazonaws.com/produtos/icon-picture.png'
      "
    />
  </div>
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    src: {
      type: String,
      default: () =>
        "https://snackly-images.s3.amazonaws.com/produtos/icon-picture.png",
    },
    size: {
      type: Number,
      default: () => 75,
    },
  },
};
</script>

<style lang="css" scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

div img {
  width: 60%;
}
</style>
