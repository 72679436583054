<template>
  <div class="row">
    <LoadingSpinner :show="loading" />

    <div class="col-md-8">
      <edit-profile-form :model="model"> </edit-profile-form>
    </div>
    <div v-if="user.companies" class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
  </div>
</template>
<script>
import LoadingSpinner from "../components/Spinner";
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";

const MANAGER_ROLE = {
  1: "Administrador",
  2: "Gerente",
  3: "Inventário",
};

export default {
  components: {
    EditProfileForm,
    UserCard,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
      model: {
        company: "",
        email: "",
        username: "",
        firstName: "",
        lastName: "",
      },
      user: {
        fullName: "",
        title: "",
        description: "",
        companies: [],
      },
    };
  },

  methods: {
    getManager() {
      let manager = this.$store.getters["manager/getManager"];
      this.user.fullName = manager.name;
      this.user.title = MANAGER_ROLE[manager.role_id];
      this.user.companies = manager.companies;
      const [firstName, lastName] = manager.name.split(" ");
      this.model.firstName = firstName;
      this.model.lastName = lastName;
      this.model.username = manager.email;
      this.model.email = manager.email;
    },
  },
  mounted() {
    this.getManager();
  },
};
</script>
<style></style>
