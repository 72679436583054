<template>
  <card :title="title">
    <div class="table-responsive table-log">
      <table class="table text-center" v-if="logList.length >= 0">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Nome do produto</th>
            <th scope="col">Máquina</th>
            <th scope="col">Saldo Estoque</th>
            <th scope="col">Media de Vendas</th>
            <th scope="col">Acionar Compras</th>
            <th scope="col">Quantidade a repor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inputLog, idx) of logList" :key="idx">
            <td>
              <div class="picture-container mx-auto">
                <img class="picture-product" :src="inputLog.image" />
              </div>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.title }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.machine_description }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.balance }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.average_sale }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ buyProducts(inputLog.balance, inputLog.average_sale) }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{
                  repositionQuantity(inputLog.balance, inputLog.average_sale)
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-show="logList.length == 0" class="text-center font-weight-bold p-4">
        Sem informações
      </p>
    </div>
  </card>
</template>

<script>
import moment from "moment";
moment.locale("pt-BR");

export default {
  name: "StockMetricsTable",
  props: {
    title: String,
    logList: {
      machine_description: String,
      average_sale: Number,
      balance: Number,
      image: String,
      product_id: String,
      title: String,
    },
  },
  methods: {
    buyProducts(balance, avarage) {
      return balance < avarage ? "Repor" : "Não repor";
    },
    repositionQuantity(balance, avarage) {
      return Math.abs(balance - avarage);
    },
  },
};
</script>

<style lang="css" scoped>
.table-log {
  height: 90vh;
}
.picture-container {
  text-align: center;
  width: 45px;
  height: 45px;
  min-width: 45px;
  text-align: center;
  line-height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

.picture-container .picture-product {
  max-width: 25px;
}
</style>