<template>
  <div
    class="modal fade"
    id="modalUpdateProduct"
    tabindex="-1"
    aria-labelledby="modalUpdateProductLabel"
    aria-hidden="true"
  >
    <LoadingSpinner :show="loading" />
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalUpdateProductLabel">
            Atualizar Produto na Máquina
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cleanFields"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Picture :imageSrc="productImage" />
          <ValidationObserver
            ref="modalUpdateProduct"
            v-slot="{ handleSubmit }"
          >
            <form
              class="row g-3 justify-content-center"
              @submit.prevent="handleSubmit(onSubmitUpdateProductInventory)"
            >
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      name="Nome do Produto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputTitle" class="form-label text-dark"
                        >Nome do Produto</label
                      >
                      <input
                        v-model="formProductUpdate.title"
                        name="city"
                        type="text"
                        class="form-control text-dark"
                        id="inputTitle"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-6">
                    <ValidationProvider
                      name="Descrição Produto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputDescription" class="form-label text-dark"
                        >Descrição Produto</label
                      >
                      <input
                        v-model="formProductUpdate.description"
                        type="text"
                        class="form-control text-dark"
                        id="inputDescription"
                      />
                      <span class="fw-normal text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      name="Preço de venda"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputUnitPrice" class="form-label text-dark"
                        >Preço de venda</label
                      >
                      <money
                        class="form-control text-dark"
                        id="inputUnitPrice"
                        v-model="formProductUpdate.price"
                        v-bind="money"
                      ></money>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-6">
                    <ValidationProvider
                      name="Desconto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="inputDiscount" class="form-label text-dark"
                        >Desconto
                      </label>
                      <money
                        class="form-control text-dark"
                        id="inputDiscount"
                        v-model="formProductUpdate.discount"
                        v-bind="money"
                      ></money>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row row py-4">
                  <div class="col-12 text-right">
                    <button
                      type="button"
                      class="btn btn-secondary mr-3"
                      data-dismiss="modal"
                      @click="cleanFields"
                    >
                      Fechar
                    </button>
                    <button type="submit" class="btn btn-primary btn-">
                      Atualizar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { validationRequire } from "../../../middleware/validations";
import Picture from "../../Picture.vue";
import LoadingSpinner from "../../Spinner.vue";
validationRequire();
export default {
  name: "ModalUpdateProduct",
  components: {
    ValidationObserver,
    ValidationProvider,
    Money,
    Picture,
    LoadingSpinner,
  },
  props: {
    productImage: String,
    machineId: String,
    formProductUpdate: {
      product_id: String,
      title: String,
      description: String,
      price: Number,
      discount: Number,
    },
  },
  data() {
    return {
      loading: false,
      warehouse_id: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.warehouse_id = warehouse_id;
  },
  methods: {
    async onSubmitUpdateProductInventory() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
          product_id: this.formProductUpdate.product_id,
          price: Math.round(this.formProductUpdate.price * 100),
          title: this.formProductUpdate.title,
          description: this.formProductUpdate.description,
          discount: Math.round(this.formProductUpdate.discount * 100),
          warehouse_id: this.warehouse_id,
        };
        await this.$http.put(`/product/machine/update`, params);
        this.$toast.success("Produto atualizado com sucesso");
        this.$emit("refresh-page");
        $(`#modalUpdateProduct`).modal("hide");
        this.$refs.modalUpdateProduct.reset();
        this.loading = false;
      } catch (err) {
        this.$refs.modalUpdateProduct.reset();
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    cleanFields() {
      this.formProductUpdate.product_id = "";
      this.formProductUpdate.title = "";
      this.formProductUpdate.description = "";
      this.formProductUpdate.price = 0;
      this.formProductUpdate.discount = 0;
    },
  },
};
</script>
<style>
</style>