<template>
  <div class="container">
    <LoadingSpinner :show="loading" />
    <CompanyConfigForm @manager-selected="receiveEmitSelectedManager" />
  </div>
</template>
  
<script>
import CompanyConfigForm from "@/components/CompanyForm/CompanyConfigForm.vue";
import LoadingSpinner from "@/components/Spinner.vue";
export default {
  name: "ManagerConfig",
  components: {
    LoadingSpinner,
    CompanyConfigForm,
  },
  data() {
    return {
      loading: false,
      isUserSelected: false,
    };
  },
  methods: {
    receiveEmitSelectedManager() {
      this.$router.push({ path: "/" });
    },
  },
  mounted() {},
};
</script>
  
<style lang="css" scoped>
.message {
  font-weight: 500;
}
</style>