<template>
  <div class="card p-5">
    <h1 class="text-center" >Editar Informações da Máquina</h1>
    <LoadingSpinner :show="loading" />
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form class="row g-3 justify-content-center" @submit.prevent="handleSubmit(onSubmit)">
        <div class="col-md-8">
          <div class="row">
            <!-- TODO: Atualizar imagem da máquina -->
            <div class="col-md-12 justify-content-center">
              <ValidationProvider name="Máquina" rules="image" ref="provider" v-slot="{ errors }">
                <label for="formImage" class="picture">
                  <span class="picture-container">
                    <img :src="machineImage" class="picture-image" alt="">
                  </span>
                  <input @change="handleFileChange" type="file" name="formImage" id="formImage" accept="image/*" tabindex="0">
                </label>
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-12">
              <ValidationProvider name="Modelo" v-slot="{ errors }">
                <label for="inputModel" class="form-label">Modelo</label>
                <input v-model="machineForm.model" type="text" class="form-control" id="inputModel">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-12">
              <label for="inputDescription" class="form-label">Descrição</label>
              <textarea v-model="machineForm.description" maxlength="255" class="form-control px-2" id="inputDescription" rows="3"></textarea>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Capacidade" rules="required" v-slot="{ errors }">
                <label for="inputCapacity" class="form-label">Capacidade <span class="text-danger">(*)</span></label>
                <input v-model="machineForm.capacity" type="number" class="form-control" id="inputCapacity">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Hardware" rules="required" v-slot="{ errors }">
                <label for="inputHardware" class="form-label">Hardware</label>
                <input v-model="machineForm.hardware" type="text" class="form-control" id="inputHardware">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-12">
              <ValidationProvider name="Data de lançamento" rules="required" v-slot="{ errors }">
                <label for="inputlaunch_date" class="form-label">Data de Lançamento <span class="text-danger">(*)</span></label>
                <input v-model="machineForm.launch_date" type="Date" class="form-control" id="inputlaunch_date">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-12 d-flex justify-content-end">
              <router-link to="/machines"  class="btn btn-secondary">Voltar</router-link>
              <button class="btn btn-primary ml-3" type="submit">Salvar alterações</button>
            </div>

          </div>
        </div>
        
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import LoadingSpinner from './Spinner.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TheMask } from 'vue-the-mask';
import { alphaNum, numericField, validationImage, validationRequire } from '../middleware/validations';


validationRequire()
validationImage()
alphaNum()
numericField()

export default {
  name: 'MachineEditForm',
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    TheMask
  },
  data() {
    return {
      machineImage: '',
      loading: false,
      machineForm: {
        machine_id: null,
        file: null,
        hardware: null,
        image: '',
        capacity: null,
        launch_date: null,
        model: null,
        description: null
      },
      prevRoute: null
    }
  },
  methods: {
    async getMachine() {
      try {
        this.loading = true;
        const machines = JSON.parse(localStorage.getItem('@Snackly:machines'));

        if (!machines) {
          this.$toast.error("Máquina não encontrado");
        }

        const machine = machines.find(item => item.machine_id == this.machineForm.machine_id);
        if (machine) {
          this.machineImage = machine.image;
          this.machineForm.hardware = machine.hardware;
          this.machineForm.capacity = machine.capacity;
          this.machineForm.launch_date = machine.launch_date;
          this.machineForm.model = machine.model;
          this.machineForm.description = machine.description;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error("Não foi possível buscar a máquina. Tente novamente");
        console.log(err);
      }
    },
    async getMachines() {
      this.loading = true;
      const { data } = await this.$http.get("/machines", { params: { company_id: this.$store.getters['company/getManagerCompany'].company_id } })
      let machines = data.machines;
      localStorage.setItem('@Snackly:machines', JSON.stringify(machines));
      this.loading = false;
      this.isRefreshing = false;
    },
    async updateMachine() {
      let formData = new FormData();
      if (!this.machineForm.image) {
        formData.append('image', this.machineImage);
      } else {
        formData.append('image', this.machineForm.image, this.machineForm.image.name)
      }
      formData.append('machine_id', this.machineForm.machine_id)
      formData.append('hardware', this.machineForm.hardware)
      formData.append('description', this.machineForm.description)
      formData.append('launch_date', this.machineForm.launch_date)
      formData.append('capacity', this.machineForm.capacity)
      formData.append('model', this.machineForm.model)
      const response = await this.$http.put(`/machine/${this.machineForm.machine_id}`, formData)
      return response
    },
    async handleFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e);

      if (valid) {
        const file = e.target.files[0];
        if (file) {
          this.loading = true;
          const reader = new FileReader();
          reader.addEventListener('load', (e) => {
            const readerTarget = e.target;
            this.machineImage = readerTarget.result
          })

          reader.readAsDataURL(file);
          this.machineForm.image = file;
          this.loading = false;
        }
      }
    },
    async onSubmit() {
      try {
        this.loading = true;
        const response = await this.updateMachine();
        await this.getMachines();
        if (response.status === 201 || response.status === 200) {
          this.$toast.success("Máquina atualizada com sucesso");
          this.loading = false;
          this.cleanFields()
          this.$router.push({ name: 'machines' })
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
    cleanFields() {
      this.machineForm.machine_id = null
      this.machineForm.hardware = null
      this.machineForm.capacity = null
      this.machineForm.launch_date = null
      this.machineForm.model = null
      this.machineForm.description = null
      this.machineForm.file = null
    },
  },
  mounted() {
    this.machineForm.machine_id = this.$route.params.id;
    this.getMachine();
  }
}
</script>

<style lang="css" scoped>
#formImage {
  display: none;
}

.picture {
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  overflow: hidden;
  text-align: center;
  line-height: 260px;
}

.picture-image {
  height: 200px;
}

#inputLocation,
#inputState {
  border-color: #2b3553;
  border-radius: 0.4285rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  transition: 260ms ease-in;
}

#inputLocation,
#inputState:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#inputDescription {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  transition: 260ms ease-in;
}

#inputDescription:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>