<template>
  <card class="p-4" title="Movimentações">
    <LoadingSpinner :show="loading" />
    <div class="row align-center justify-content-between">
      <div class="col-12 col-sm-6">
        <label for="inputMachine" class="form-label text-light"
          >Filtrar por máquina</label
        >
        <select
          v-model="selectedMachine"
          class="form-control"
          id="inputMachine"
          @change="getMachineStockLog()"
        >
          <option class="bg-dark" :selected="!selectedMachine" :value="null">
            --- Todas as máquinas ---
          </option>
          <option
            v-for="machine in machines"
            :value="machine.machine_id"
            :key="machine.machine_id"
            class="bg-dark"
          >
            {{ machine.description }}
          </option>
        </select>
      </div>
      <div class="col-12 col-sm-6">
        <label for="inputProduct" class="form-label text-light"
          >Filtrar por produto</label
        >
        <select
          v-model="selectedProduct"
          class="form-control"
          id="inputProduct"
        >
          <option class="bg-dark" :selected="!selectedProduct" :value="null">
            --- Todas os produtos ---
          </option>
          <option
            v-for="product in products"
            :value="product.product_id"
            :key="product.product_id"
            class="bg-dark"
          >
            {{ product.title }}
          </option>
        </select>
      </div>
    </div>
    <DatePicker
      @receiveStartDate="receiveStartDate"
      @receiveEndDate="receiveEndDate"
    />
    <TabNavigation
      :mode="toggleNavBar"
      :labels="{ first: 'Entradas/Saídas', second: 'Transição' }"
      @toggle-mode="receiveEmitToggleMode"
    />
    <div v-if="!toggleNavBar">
      <MovimentsMachineLogTable
        title="Entradas Máquina"
        :logList="machineStockLogInput"
      />
      <MovimentsMachineLogTable
        title="Saídas Máquina"
        :logList="machineStockLogOutput"
      />
    </div>
    <div v-else>
      <MovimentsStockLogTable
        title="Entradas estoque"
        :logList="stockLogInput"
      />
      <MovimentsStockLogTable
        title="Saídas estoque"
        :logList="stockLogOutput"
      />
    </div>
  </card>
</template>
<script>
import BaseTable from "../components/BaseTable.vue";
import DatePicker from "../components/DatePicker.vue";
import MovimentsMachineLogTable from "../components/Moviments/MovimentsMachineLogTable.vue";
import MovimentsStockLogTable from "../components/Moviments/MovimentsStockLogTable.vue";
import LoadingSpinner from "../components/Spinner.vue";
import TabNavigation from "../components/TabNavigation.vue";

export default {
  components: {
    LoadingSpinner,
    TabNavigation,
    MovimentsMachineLogTable,
    MovimentsStockLogTable,
    BaseTable,
    DatePicker,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      selectedMachine: "",
      selectedProduct: "",
      toggleNavBar: false,
      loading: false,
      machineStockLog: [],
      stockLog: [],
      machines: [],
      products: [],
      warehouse_id: "",
      company_id: "",
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.initialLoading();
  },
  methods: {
    receiveStartDate(date) {
      this.startDate = date;
    },
    receiveEndDate(date) {
      this.endDate = date;
    },
    async handleDateChanges() {
      await this.getMachineStockLog();
      await this.getStockLog();
    },
    async initialLoading() {
      await this.getStockLog();
      await this.getProducts();
      await this.getMachines();
      await this.getMachineStockLog();
    },
    receiveEmitToggleMode(mode) {
      this.toggleNavBar = mode;
    },
    async getProducts() {
      try {
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        this.loading = true;
        const { data } = await this.$http.get("/products", {
          params,
        });
        this.products = data.products;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id: this.company_id,
          },
        });

        this.machines = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getStockLog() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("stock/log", {
          params,
        });
        const { stockLog } = response.data;
        this.stockLog = stockLog;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getMachineStockLog() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          machine_id: this.selectedMachine,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("machine/stock/log", {
          params,
        });
        const { stockLog } = response.data;
        this.machineStockLog = stockLog;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    filteredStockTableStockLog(actions) {
      return this.stockLog.filter((log) => {
        const isAction = actions.includes(log.action);
        const isSelectedProduct = this.selectedProduct === log.product.id;
        return isAction && (!this.selectedProduct || isSelectedProduct);
      });
    },
    filteredMachineTableStockLog(actions) {
      return this.machineStockLog.filter((log) => {
        const isAction = actions.includes(log.action);
        const isSelectedProduct = this.selectedProduct === log.product.id;
        return isAction && (!this.selectedProduct || isSelectedProduct);
      });
    },
  },
  computed: {
    machineStockLogInput() {
      const inputActions = [1, 2, 3, 14];
      return this.filteredMachineTableStockLog(inputActions);
    },
    machineStockLogOutput() {
      const outputActions = [4, 5, 6, 7, 8, 9, 10];
      return this.filteredMachineTableStockLog(outputActions);
    },
    stockLogInput() {
      const inputActions = [1, 2, 3, 4, 5, 6];
      return this.filteredStockTableStockLog(inputActions);
    },
    stockLogOutput() {
      const outputActions = [7, 8, 9, 10, 11, 12, 13];
      return this.filteredStockTableStockLog(outputActions);
    },
  },
  watch: {
    startDate() {
      this.handleDateChanges();
    },
    endDate() {
      this.handleDateChanges();
    },
  },
};
</script>
<style lang="css" scoped>
</style>
