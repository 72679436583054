<template>
  <card title="Máquinas" v-show="machinePurchases">
    <LoadingSpinner :show="loading" />

    <!-- <div class="filter-container">
      <div class="container-input initial text-left">
        <label for="start-date">De</label>
        <input v-model="dateFilter.start" type="date" id="start-date" />
      </div>

      <div class="container-input final text-left">
        <label for="end-date">Até</label>
        <input v-model="dateFilter.end" type="date" id="end-date" />
      </div>
    </div> -->

    <!-- <div class="row my-2 justify-content-start">
      <div class="col-md-4 text-left">
        <label>Procurar máquinas</label>
        <base-input v-model="search" addonLeftIcon="tim-icons icon-zoom-split" placeholder="Ex:. Coca cola"></base-input>
      </div>
    </div> -->

    <div class="row">
      <div
        class="card table-responsive"
        v-for="machine in machinePurchases"
        :key="machine.machine_id"
      >
        <table
          class="table text-center"
          data-toggle="collapse"
          :data-target="'#collapse' + machine.machine_id"
          aria-expanded="false"
          :aria-controls="'#collapse' + machine.machine_id"
        >
          <thead>
            <tr>
              <th class="machine-image-container">Imagem</th>
              <th>Endereço</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="machine-image-container">
                <div class="picture-container mx-auto">
                  <img
                    class="picture-machine"
                    :src="machine.image"
                    :alt="machine.description"
                  />
                </div>
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ machine.address }}
                </p>
              </td>
              <td>
                <p class="text-white mx-2 font-weight-bold">
                  {{ toBRL(machine.total) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="collapse" :id="'collapse' + machine.machine_id">
          <table id="order" class="table text-center">
            <thead>
              <tr>
                <th scope="col">N° do Pedido</th>
                <th scope="col">Nome do usuário</th>
                <th scope="col">Email</th>
                <th scope="col">Data</th>
                <th scope="col">Status</th>
                <th scope="col">Tipo</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody
              v-for="purchase in machine.purchases"
              :key="purchase.purchase_id"
            >
              <tr
                data-toggle="collapse"
                :data-target="'#collapse' + purchase.purchase_id"
                aria-expanded="false"
                :aria-controls="'#collapse' + purchase.purchase_id"
              >
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    <span class="badge badge-info d-block"
                      >#{{ purchase.purchase_id }}</span
                    >
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ purchase.name }}
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ purchase.email }}
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ dateFormatted(purchase.date) }}
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    <span
                      class="badge d-block"
                      :class="paymentStatus(purchase.status)"
                      >{{
                        PAYMENTS_STATUS[purchase.status.toLowerCase()]
                      }}</span
                    >
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    <span class="badge badge-info d-block">{{
                      PAYMENTS_TYPE[purchase.type.toLowerCase()]
                    }}</span>
                  </p>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ toBRL(purchase.total) }}
                  </p>
                </td>
              </tr>
              <tr
                class="collapse"
                :id="'collapse' + purchase.purchase_id"
                v-for="(product, index) in purchase.products"
                :key="purchase.purchase_id + index"
              >
                <td>
                  <div class="picture-container product mx-auto">
                    <img
                      class="picture-product"
                      :src="product.image"
                      :alt="product.title"
                    />
                  </div>
                </td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ product.title }}
                  </p>
                </td>
                <td>- - - -</td>
                <td>- - - -</td>
                <td>- - - -</td>
                <td>
                  <p class="text-white mx-2 font-weight-bold">
                    {{ product.amount }}x {{ toBRL(product.unit_price) }}
                  </p>
                </td>
                <td>- - - -</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import moment from "moment-timezone";
import LoadingSpinner from "../components/Spinner.vue";
import { format } from "../mixins/format.js";
export default {
  components: {
    LoadingSpinner,
  },
  mixins: [format],
  data() {
    return {
      loading: false,
      PAYMENTS_STATUS: {
        paid: "Pago",
        pending: "Pendente",
        canceled: "Cancelado",
      },
      PAYMENTS_TYPE: {
        credit: "Crédito",
        creditcard: "Crédito",
        debit: "Débito",
        debitcard: "Débito",
        pix: "Pix",
      },
      dateFilter: {
        start: "",
        end: "",
      },
      machinePurchases: [],
      search: "",
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }

    const selectedCompany =
      this.$store.getters["company/getManagerCompany"].company_id;
    if (!selectedCompany) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
    } else {
      this.getMachinesResponse();
    }
  },
  methods: {
    dateFormatted(date) {
      const desiredTimezone = "America/Recife";
      const datetime = moment.utc(date);
      const dateTimeRecife = datetime.clone().tz(desiredTimezone);
      return dateTimeRecife.format("L LT");
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value / 100);
    },
    paymentStatus(payment) {
      if (payment.toLowerCase() == "paid") return "badge-success";
      if (payment.toLowerCase() == "pending") return "badge-warning";
      if (payment.toLowerCase() == "canceled") return "badge-danger";
    },
    async getMachinesResponse() {
      try {
        this.loading = true;
        const company_id =
          this.$store.getters["company/getManagerCompany"].company_id;
        const response = await this.$http.get(
          `/machine/purchase?page=${
            this.currentPage ? this.currentPage : 1
          }&company_id=${company_id}`
        );
        const { purchases } = response.data;
        this.machinePurchases = purchases;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>

<style lang="css" scoped>
#collapseFilters {
  max-width: 300px;
  width: 100%;
  background: white;
  border-radius: 3px;
  padding: 1rem;
}

.filter-container {
  margin: 16px 0 16px 6px;
  border-radius: 16px;
  display: flex;
}

.container-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 230px;
}

.container-input + .container-input {
  margin-left: 1rem;
}

.container-input label {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
}

.container-input input[type="date"] {
  border: none;
  padding: 0.875rem;
  border-radius: 4px;
}

.container-input #initial-date {
  display: inline-block;
  margin-bottom: 5px;
}

.card {
  padding: 40px !important;
}

table {
  border-radius: 12px;
  background: transparent;
}

thead th {
  padding: 40px 0;
}

tbody td {
  border-color: rgba(14, 14, 20, 0.329) mportant;
  cursor: pointer !important;
}

thead th {
  color: #ffffff !important;
}

tbody p span {
  font-size: 12px;
}

.picture-container {
  text-align: center;
  width: 75px;
  min-width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

.picture-container.product {
  width: 48px;
  height: 48px;
  min-width: 48px;
  line-height: 48px;
}

.picture-container.product > .picture-product {
  max-width: 28px;
}

.picture-container .picture-product {
  max-width: 55px;
}

.picture-container .picture-machine {
  width: 75px;
}
</style>