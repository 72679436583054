var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"preview-modal":false,"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","htmlToPdfOptions":{
      filename: (_vm.name + "_" + (_vm.dateFormatted(new Date(), true))),
      margin: [4, 0, 10, 0],
      html2canvas: {
        scale: 2,
      },
    }},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('div',{staticStyle:{"padding":"20px"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"128px"},attrs:{"src":require("../../../public/img/favicon.png"),"alt":"Snackly Logo"}})]),_c('h3',{staticClass:"text-dark font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.name.toUpperCase())+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-end","flex-direction":"row-reverse"}},[_c('p',{staticClass:"text-dark",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.user.name)+" | "+_vm._s(_vm.user.email)+" ")]),_c('p',{staticClass:"text-dark",staticStyle:{"font-size":"12px"}},[_vm._v(" Data do inventário "+_vm._s(_vm.dateFormatted(_vm.date, true))+" ")])]),_c('div',{staticStyle:{"margin-top":"1rem"}},[_c('table',{attrs:{"table":"","id":"customers"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Valor total diferença")]),_c('th',[_vm._v("Valor unitário")]),_c('th',[_vm._v("Diferença")]),_c('th',[_vm._v("Quant. Física")]),_c('th',[_vm._v("Quant. Sistema")]),_c('th',[_vm._v("Validade")]),_c('th',[_vm._v("Nome")])])]),_c('tbody',{staticClass:"text-dark"},_vm._l((_vm.inventoryItems),function(product,idx){return _c('tr',{key:idx,staticClass:"avoid-break"},[_c('td',{staticClass:"table-td",class:{
                  negative: Number(product.total_value) < 0,
                }},[_vm._v(" "+_vm._s(_vm.toBRL(Math.abs(product.total_value)))+" "+_vm._s(Number(product.total_value) >= 0 ? "" : "-")+" ")]),_c('td',[_vm._v(_vm._s(_vm.toBRL(product.unit_value)))]),_c('td',{staticClass:"table-td",class:{
                  negative: Number(product.quantity_difference) < 0,
                }},[_vm._v(" "+_vm._s(Math.abs(Number(product.quantity_difference)))+" "+_vm._s(Number(product.quantity_difference) >= 0 ? "" : "-")+" ")]),_c('td',{staticClass:"table-td"},[_vm._v(_vm._s(product.physical_quantity))]),_c('td',{staticClass:"table-td"},[_vm._v(_vm._s(product.system_quantity))]),_c('td',{staticClass:"table-td"},[_vm._v(" "+_vm._s(_vm.dateFormatted(product.expiration))+" ")]),_c('td',[_vm._v(_vm._s(product.title))])])}),0)])]),_c('table',{staticClass:"mt-5",attrs:{"id":"table-total"}},[_c('tbody',{staticClass:"text-dark"},[_c('tr',[_c('td',{staticClass:"table-td",class:{
                negative: Number(_vm.negativeTotal) < 0,
              }},[_vm._v(" "+_vm._s(_vm.toBRL(Math.abs(Number(_vm.negativeTotal))))+" "+_vm._s(Number(_vm.negativeTotal) >= 0 ? "" : "-")+" ")]),_c('td',{staticClass:"table-th"},[_vm._v("Total déficit")])]),_c('tr',[_c('td',{staticClass:"table-td",class:{
                negative: Number(_vm.positiveTotal) < 0,
              }},[_vm._v(" "+_vm._s(_vm.toBRL(Math.abs(Number(_vm.positiveTotal))))+" "+_vm._s(Number(_vm.positiveTotal) >= 0 ? "" : "-")+" ")]),_c('td',{staticClass:"table-th"},[_vm._v("Total excedente")])])])]),_c('p',{staticClass:"text-dark mt-5 text-center font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v(" Luttos | "+_vm._s(_vm.name)+" "+_vm._s(_vm.dateFormatted(_vm.date, true))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }