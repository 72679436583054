<template>
  <div
    class="modal fade"
    id="modalRemoveProduct"
    tabindex="-1"
    aria-labelledby="modalRemoveProductLabel"
    aria-hidden="true"
  >
    <LoadingSpinner :show="loading" />
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header py-4">
          <h5 class="modal-title" id="modalRemoveProductLabel">
            Remover produto do estoque da máquina
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cleanFields"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Picture :imageSrc="productImage" />
        <div class="modal-body">
          <ValidationObserver
            ref="modalRemoveProduct"
            v-slot="{ handleSubmit }"
          >
            <form
              class="row g-3 justify-content-center"
              @submit.prevent="handleSubmit(onSubmitRemoveProductInventory)"
            >
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Disponibilidade"
                      rules="required|min_value:1"
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputAvailability"
                        class="form-label text-dark"
                        >Quantidade que deseja remover</label
                      >
                      <input
                        v-model="amount"
                        type="number"
                        min="0"
                        class="form-control text-dark"
                        id="inputAvailability"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Unidade Comercial"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputProductMoviment"
                        class="form-label text-dark"
                        >Tipo Movimentação</label
                      >
                      <select
                        v-model="action"
                        class="form-control text-dark"
                        id="inputProductMoviment"
                      >
                        <option
                          v-for="action in removeActions"
                          :value="action.value"
                          :key="action.value"
                        >
                          {{ action.label }}
                        </option>
                      </select>
                      <span class="fw-normal text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row row py-4">
                  <div class="col-12 text-right">
                    <button
                      type="button"
                      class="btn btn-secondary mr-3"
                      data-dismiss="modal"
                      @click="cleanFields"
                    >
                      Fechar
                    </button>
                    <button type="submit" class="btn btn-primary btn-">
                      Remover
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { minValue, validationRequire } from "../../../middleware/validations";
import Picture from "../../Picture.vue";
import LoadingSpinner from "../../Spinner.vue";
moment.locale("pt-BR");
minValue();
validationRequire();
export default {
  name: "ModalRemoveProduct",
  components: {
    ValidationObserver,
    ValidationProvider,
    Picture,
    LoadingSpinner,
    Multiselect,
  },
  props: {
    productImage: String,
    machineId: String,
    formRemoveProduct: {
      product_id: String,
    },
  },
  data() {
    return {
      loading: false,
      removeActions: [
        { label: "SAIDA PERDA AVARIA", value: 4 },
        { label: "SAIDA PERDA VENCIMENTO", value: 5 },
        { label: "SAIDA PERDA ROUBO", value: 6 },
        { label: "SAIDA OUTRAS PERDAS", value: 7 },
        { label: "SAIDA DEVOLUCAO ESTOQUE", value: 8 },
        { label: "SAIDA OUTRAS", value: 9 },
        { label: "SAIDA VENDAS", value: 10 },
      ],
      action: 4,
      amount: 0,
      expiration: "",
      warehouse_id: null,
      company_id: null,
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.warehouse_id = warehouse_id;
    this.company_id = company_id;
  },
  methods: {
    cleanFields() {
      this.amount = 0;
      this.action = 4;
      this.$refs.modalRemoveProduct.reset();
    },
    async onSubmitRemoveProductInventory() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
          product_id: this.formRemoveProduct.product_id,
          amount: Number(this.amount),
          action: this.action,
          warehouse_id: this.warehouse_id,
        };
        await this.$http.post(`/product/machine/remove`, params);
        this.$toast.success("Produto atualizado com sucesso");
        this.$emit("refresh-page");
        $(`#modalRemoveProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
      } catch (err) {
        $(`#modalRemoveProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
};
</script>
<style lang="css" scoped>
.multiselect__element,
.multiselect__input,
.multiselect__placeholder,
.multiselect__single {
  font-size: 0.75rem;
  color: #212529;
}
.form-control[disabled] {
  background-color: #fff;
  border-color: #e8e8e8;
  color: #838383 !important;
}
</style>