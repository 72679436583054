<template>
  <card>
    <h5 slot="header" class="title">Editar Perfil</h5>
    <div class="row">
      <div class="col-md-12 px-md-3">
        <base-input
          label="Usuário"
          placeholder="Usuário"
          v-model="model.username"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input label="Nome" v-model="model.firstName" placeholder="Nome">
        </base-input>
      </div>
      <div class="col-md-6 pl-md-1">
        <base-input
          label="Sobrenome"
          v-model="model.lastName"
          placeholder="Sobrenome"
        >
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill>Salvar</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
<style>
</style>
