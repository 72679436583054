<template>
  <div class="card p-5">
    <h1 class="text-center">Criar Máquina</h1>
    <LoadingSpinner :show="loading" />
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form class="row g-3 justify-content-center" @submit.prevent="handleSubmit(onSubmit)">
        <div class="col-md-8">
          <div class="row">

            <div class="col-12">
              <h2>Localização</h2>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Cep" rules="required" v-slot="{ errors }">
                <label for="inputPostalCode" class="form-label">Cep <span class="text-danger">(*)</span></label>
                <TheMask class="form-control" id="inputPostalCode" :mask="['#####-###']" v-model="formLocation.postalCode" type="text"/>
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Estado" rules="required" v-slot="{ errors }">
                <label for="inputState">Estado</label>
                <select v-model="formLocation.state" id="inputState"  class="form-select w-100 d-block my-2 py-2">
                  <option class="bg-dark" selected disabled>Selecione um Estado <span class="text-danger">(*)</span></option>
                  <option class="bg-dark" :value="value" v-for="(name, value) in formLocation.states" :key="value" >{{ name }} - {{ value }}</option>
                </select>
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>
           
            <div class="col-md-6" >
              <ValidationProvider name="Cidade" rules="required" v-slot="{ errors }">
                <label for="inputCity" class="form-label">Cidade <span class="text-danger">(*)</span></label>
                <input v-model="formLocation.city" name="city" type="text" class="form-control" id="inputCity">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Endereço" rules="required" v-slot="{ errors }">
                <label for="inputAddress" class="form-label">Endereço <span class="text-danger">(*)</span></label>
                <input v-model="formLocation.address" type="text" class="form-control" id="inputAddress">
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Bairro" rules="required" v-slot="{ errors }">
                <label for="inputDistrict" class="form-label">Bairro <span class="text-danger">(*)</span></label>
                <input v-model="formLocation.district" type="text" class="form-control" id="inputDistrict">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Número" rules="numeric" v-slot="{ errors }">
                <label for="inputNumber" class="form-label">Número</label>
                <input v-model="formLocation.number" type="text" class="form-control" id="inputNumber">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div> 

            <div class="col-md-12">
              <label for="inputComplement" class="form-label">Complemento</label>
              <input v-model="formLocation.complement" type="text" maxlength="75" class="form-control" id="inputComplement">
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Latitude" rules="required" v-slot="{ errors }">
                <label for="inputLatitude" class="form-label">Latitude <span class="text-danger">(*)</span></label>
                <input v-model="formLocation.coordinates.latitude" type="text" class="form-control" id="inputLatitude">
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Longitude" rules="required" v-slot="{ errors }">
                <label for="inputLongitude" class="form-label">Longitude <span class="text-danger">(*)</span></label>
                <input maxlength="10" v-model="formLocation.coordinates.longitude" type="text" class="form-control" id="inputLongitude">
                <span class="fw-normal text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <h2>Máquina</h2>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Hardware" v-slot="{ errors }">
                <label for="inputHardware" class="form-label">Hardware</label>
                <input v-model="formMachine.hardware" type="text" class="form-control" id="inputHardware">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Máquina" rules="image" ref="provider" v-slot="{ errors }">
                <label for="formFile" class="form-label">Imagem da Máquina <span class="text-danger"></span></label>
                <input @change="handleFileChange" type="file" name="formFile" class="form-control" id="formFile">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Capacidade" rules="required" v-slot="{ errors }">
                <label for="inputCapacity" class="form-label">Capacidade <span class="text-danger">(*)</span></label>
                <input v-model="formMachine.capacity" type="number" class="form-control" id="inputCapacity">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="Data de lançamento" rules="required" v-slot="{ errors }">
                <label for="inputLaunchDate" class="form-label">Data de Lançamento <span class="text-danger">(*)</span></label>
                <input v-model="formMachine.launchDate" type="Date" class="form-control" id="inputLaunchDate">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-12">
              <ValidationProvider name="Modelo" v-slot="{ errors }">
                <label for="inputModel" class="form-label">Modelo</label>
                <input v-model="formMachine.model" type="text" class="form-control" id="inputModel">
                <p class="fw-normal text-danger p-relative">{{ errors[0] }}</p> 
              </ValidationProvider>
            </div>

            <div class="col-md-12">
                <label for="inputDescription" class="form-label">Descrição</label>
                <textarea v-model="formMachine.description" maxlength="255" class="form-control px-2" id="inputDescription" rows="3"></textarea>
            </div>

            <div class="col-12 d-flex justify-content-end">
              <router-link to="/machines" class="btn btn-secondary">Voltar</router-link>
              <button class="btn btn-primary  ml-3" type="submit">Cadastrar Máquina</button>
            </div>

          </div>
        </div>
        
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TheMask } from 'vue-the-mask';
import { alphaNum, numericField, validationImage, validationRequire } from '../middleware/validations';
import LoadingSpinner from './Spinner.vue';


validationRequire()
validationImage()
alphaNum()
numericField()

export default {
  name: 'FormMachine',
  components: {
    LoadingSpinner,
    ValidationProvider,
    ValidationObserver,
    TheMask
  },
  data() {
    return {
      loading: false,
      formLocation: {
        state: '',
        city: '',
        address: '',
        postalCode: null,
        complement: '',
        coordinates: {
          longitude: null,
          latitude: null,
        },
        district: null,
        number: null,
        states: {
          AC: "Acre",
          AL: "Alagoas",
          AP: "Amapá",
          AM: "Amazonas",
          BA: "Bahia",
          CE: "Ceará",
          ES: "Espírito Santo",
          GO: "Goiás",
          MA: "Maranhão",
          MT: "Mato Grosso",
          MS: "Mato Grosso do Sul",
          MG: "Minas Gerais",
          PA: "Pará",
          PB: "Paraíba",
          PR: "Paraná",
          PE: "Pernambuco",
          PI: "Piauí",
          RJ: "Rio de Janeiro",
          RN: "Rio Grande do Norte",
          RS: "Rio Grande do Sul",
          RO: "Rondônia",
          RR: "Roraima",
          SC: "Santa Catarina",
          SP: "São Paulo",
          SE: "Sergipe",
          TO: "Tocantins",
          DF: "Distrito Federal"
        }
      },
      formMachine: {
        file: null,
        hardware: null,
        image: '',
        capacity: null,
        launchDate: null,
        model: null,
        description: null
      },
    }
  },
  computed: {
    cep() {
      return this.formLocation.postalCode;
    }
  },
  watch: {
    cep(newValue) {
      if (newValue.length === 8) {
        this.getCep(newValue)
      }
    }
  },

  methods: {
    async cepAPI(cep) {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      return data
    },
    async getCep(cep) {
      try {
        const { logradouro, bairro, localidade, uf } = await this.cepAPI(cep)

        this.formLocation.address = logradouro
        this.formLocation.district = bairro
        this.formLocation.city = localidade
        this.formLocation.state = uf
      } catch (err) {
        console.log(err)
      }
    },
    async registerMachine(form) {
      const response = await this.$http.post('/machine', form)
      return response;
    },
    async handleFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e);
      if (valid) {
        const file = e.target.files[0]
        this.formMachine.file = file
      }
    },
    cleanFields() {
      this.formLocation.state = ''
      this.formLocation.city = ''
      this.formLocation.address = ''
      this.formLocation.postalCode = null
      this.formLocation.complement = ''
      this.formLocation.coordinates.longitude = null
      this.formLocation.coordinates.latitude = null
      this.formLocation.district = null
      this.formLocation.number = null

      this.formMachine.file = null
      this.formMachine.hardware = null
      this.formMachine.image = ''
      this.formMachine.capacity = null
      this.formMachine.launchDate = null
      this.formMachine.model = null
      this.formMachine.description = null
      this.formMachine.file = null
    },
    async onSubmit() {
      try {
        this.loading = true;
        const selectedCompany = this.$store.getters['company/getManagerCompany'].company_id;
        if (!selectedCompany) {
          this.$toast.info("Escolha uma Organização. Perfil > Selecionar organização");
          throw new Error('Escolha uma Organização. Perfil > Selecionar organização')
        }
        let formData = new FormData();
        formData.append('state', this.formLocation.state)
        formData.append('city', this.formLocation.city)
        formData.append('address', this.formLocation.address)
        formData.append('postal_code', this.formLocation.postalCode)
        formData.append('complement', this.formLocation.complement)
        formData.append('longitude', this.formLocation.coordinates.longitude)
        formData.append('latitude', this.formLocation.coordinates.latitude)
        formData.append('district', this.formLocation.district)
        formData.append('number', this.formLocation.number)
        formData.append('company_id', selectedCompany)

        formData.append('hardware', this.formMachine.hardware)
        formData.append('description', this.formMachine.description)
        formData.append('launch_date', this.formMachine.launchDate)
        formData.append('capacity', this.formMachine.capacity)
        formData.append('model', this.formMachine.model)
        formData.append('mqtt_topic', 'snackly')
        if (this.formMachine.file) {
          formData.append('image', this.formMachine.file, this.formMachine.file.name)
        }
        const response = await this.registerMachine(formData);
        if (response.status === 201 || response.status === 200) {
          this.$toast.success("Ótimo! Sua máquina foi criada com sucesso!");
          this.cleanFields()
          this.loading = false;
          this.$router.push({ name: 'machines' })
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    }
  },
}
</script>

<style lang="css" scoped>
#inputLocation,
#inputState {
  border-color: #2b3553;
  border-radius: 0.4285rem;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  transition: 260ms ease-in;
}

#inputLocation,
#inputState:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#inputDescription {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  transition: 260ms ease-in;
}

#inputDescription:focus {
  border-color: #e14eca;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>