<template>
  <card :title="title">
    <div class="table-responsive table-log">
      <table class="table text-center" v-if="logList.length >= 0">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Nome do produto</th>
            <th scope="col">Máquina</th>
            <th scope="col">Detalhes</th>
            <th scope="col">Preço</th>
            <th scope="col">Desconto</th>
            <th scope="col">Data</th>
            <th scope="col">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inputLog, idx) of logList" :key="idx">
            <td>
              <ProductImage :size="45" :src="inputLog.product.image" />
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.product.name }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.machine.name }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.detail }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ toBRL(inputLog.price / 100) }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ toBRL(inputLog.discount / 100) }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-info d-block py-2">{{
                  dateFormatted(inputLog.date)
                }}</span>
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ inputLog.quantity }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-show="logList.length == 0" class="text-center font-weight-bold p-4">
        Sem informações
      </p>
    </div>
  </card>
</template>

<script>
import moment from "moment";
import ProductImage from "../ProductImage.vue";
moment.locale("pt-BR");

export default {
  name: "MovimentsMachineLogTable",
  components: {
    ProductImage,
  },
  props: {
    title: String,
    logList: {
      machine: {
        name: String,
      },
      product: {
        name: String,
        image: String,
      },
      detail: String,
      price: Number,
      discount: Number,
      quantity: Number,
      date: String,
    },
  },
  methods: {
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
  },
};
</script>

<style lang="css" scoped>
.table-log {
  height: 50vh;
}
</style>