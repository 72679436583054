<template>
  <div
    class="image-container"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      'line-height': `${size}px`,
    }"
  >
    <img class="image-content" :src="imageSrc" />
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      default:
        "https://snackly-images.s3.amazonaws.com/produtos/icon-picture.png",
      type: String,
    },
    size: {
      type: Number,
      default: 75,
    },
  },
};
</script>

<style scoped>
.image-container {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f3f3f3;
}

.image-container > .image-content {
  width: 80%;
}
</style>