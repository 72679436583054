<template>
  <div class="card">
    <div class="row">
      <h1>Id do Pedido {{purchaseId}}</h1>
    </div>
  </div>
</template>
<script>
import api from "../services/api"

export default {
  data() {
    return {
      purchaseId: null
    }
  },
  methods: {
    
  },
  mounted() {
    this.purchaseId = this.$route.params.id
  }
};
</script>
<style>
</style>
