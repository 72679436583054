var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('button',{staticClass:"btn btn-info",on:{"click":_vm.generateReport}},[_vm._v(" Gerar relatório ")])]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"preview-modal":false,"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","htmlToPdfOptions":{
      filename: ("relatório_vendas_" + (_vm.dateFormatted(new Date(), true))),
      margin: [4, 0, 10, 0],
      html2canvas: {
        scale: 2,
      },
    }},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('div',{staticStyle:{"padding":"20px"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-end","flex-direction":"row-reverse"}},[_c('img',{staticStyle:{"width":"128px"},attrs:{"src":require("../../../public/img/favicon.png"),"alt":"Snackly Logo"}}),_c('p',{staticClass:"text-dark",staticStyle:{"font-size":"12px"}},[_vm._v(" gerado em "+_vm._s(_vm.dateFormatted(new Date()))+" ")])]),_c('h3',{staticClass:"text-dark"},[_vm._v("Gráfico de Produtos")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-end","flex-direction":"row-reverse"}},[_c('div',[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.startDate && _vm.endDate),expression:"startDate && endDate"}],staticClass:"text-dark"},[_vm._v(" Data inicial "),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.dateFormatted(_vm.startDate)))]),_c('br'),_vm._v(" Data final "),_c('span',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.dateFormatted(_vm.endDate)))])])]),_c('div',[_c('p',{staticClass:"items-total text-dark"},[_vm._v(" Valor total "+_vm._s(_vm.toBRL(_vm.total / 100))+" ")])])]),_vm._l((_vm.chartData),function(data,idx){return _c('div',{key:idx,staticClass:"avoid-break",staticStyle:{"width":"100%","margin":"0 auto"}},[_c('apexchart',{attrs:{"width":"100%","type":"bar","options":{
            chart: {
              id: 'productsChart',
              stacked: true,
            },
            xaxis: {
              categories: data.categories,
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
            yaxis: {
              show: true,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              } ],
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                  total: {
                    enabled: true,
                    formatter: function (
                      value,
                      ref
                    ) {
                      var seriesIndex = ref.seriesIndex;
                      var dataPointIndex = ref.dataPointIndex;
                      var w = ref.w;

                      var series = w.config.series;
                      var unitValue = 0;
                      var quantity = 0;
                      var quantitySeries = series.find(
                        function (s) { return s.name === 'Quantidade'; }
                      );
                      var unitValueSeries = data.unitPrices;
                      if (quantitySeries) {
                        quantity = quantitySeries.data[dataPointIndex] || 0;
                      }
                      if (unitValueSeries) {
                        unitValue = unitValueSeries.data[dataPointIndex] || 0;
                      }
                      return ("" + (_vm.toBRL((unitValue / 100) * quantity)));
                    },
                    offsetX: 10,
                    offsetY: 0,
                    style: {
                      color: '#cb523d',
                      fontSize: '14px',
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            legend: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            colors: ['#e4c465', '#cb523d'],
            dataLabels: {
              position: 'top',
              enabled: true,
              textAnchor: 'middle',
              style: {
                fontSize: '14px',
                colors: ['#fff'],
              },
              offsetX: 0,
              dropShadow: {
                enabled: true,
              },
            },
          },"series":data.series}})],1)}),_c('div',{staticStyle:{"margin-top":"72px"}},[_c('table',{attrs:{"table":"","id":"customers"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Valor total")]),_c('th',[_vm._v("Desconto")]),_c('th',[_vm._v("Preço Venda")]),_c('th',[_vm._v("Quantidade")]),_c('th',[_vm._v("Produto")]),_c('th',[_vm._v("Máquina")])])]),_c('tbody',{staticClass:"text-dark"},[_vm._l((_vm.logList),function(sale,idx){return _c('tr',{key:idx,staticClass:"avoid-break"},[_c('td',[_vm._v(" "+_vm._s(_vm.toBRL(((sale.price - sale.discount) * sale.quantity) / 100))+" ")]),_c('td',[_vm._v(_vm._s(_vm.toBRL(sale.discount / 100)))]),_c('td',[_vm._v(_vm._s(_vm.toBRL(sale.price / 100)))]),_c('td',[_vm._v(_vm._s(sale.quantity))]),_c('td',[_vm._v(_vm._s(sale.product.name))]),_c('td',[_vm._v(_vm._s(sale.machine.name))])])}),_c('tr',[_c('td',{staticClass:"items-total"},[_vm._v(_vm._s(_vm.toBRL(_vm.total / 100)))]),_c('td',{staticClass:"text-left"},[_vm._v("-----")]),_c('td',{staticClass:"text-left"},[_vm._v("-----")]),_c('td',{staticClass:"text-left"},[_vm._v("-----")]),_c('td',{staticClass:"text-left"},[_vm._v("-----")]),_c('td',{staticClass:"items-total"},[_vm._v("TOTAL")])])],2)])])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }