<template>
  <div class="col-12 mx-auto">
    <LoadingSpinner :show="loading" />
    <div class="table-responsive">
      <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">N° Pedido</th>
            <th scope="col">Produto</th>
            <th scope="col">Data da Venda</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Preço de venda</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, idx) of filteredSales" :key="idx">
            <td>
              <div class="picture-container mx-auto">
                <img class="picture-product" :src="product.image" />
              </div>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                #{{ product.purchase_id }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.title }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-info d-block py-2">{{
                  dateFormatted(product.date)
                }}</span>
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-info d-block py-2">{{
                  product.quantity
                }}</span>
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-default d-block py-2">{{
                  toBRL(product.price / 100)
                }}</span>
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <span class="badge badge-default d-block py-2">{{
                  toBRL((product.price * product.quantity) / 100)
                }}</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LoadingSpinner from "./Spinner.vue";
moment.locale("pt-BR");

export default {
  name: "SaleTableList",
  components: {
    LoadingSpinner,
  },
  props: {
    machineId: String,
    search: String,
  },
  data() {
    return {
      sales: [],
      loading: false,
    };
  },
  mounted() {
    this.getSales();
  },
  methods: {
    dateFormatted(date) {
      return moment(date).format("L");
    },
    toBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    async getSales() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
        };
        const { data } = await this.$http.get("/sales", {
          params,
        });
        const { sales } = await data;
        this.sales = sales;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
  computed: {
    filteredSales() {
      return this.sales
        .filter((sale) => {
          return (
            sale.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
          );
        })
        .sort((salesA, salesB) => {
          if (salesA.purchase_id < salesB.purchase_id) return -1;
          if (salesA.purchase_id > salesB.purchase_id) return 1;
          return 0;
        });
    },
  },
};
</script>

<style lang="css">
.picture-container {
  text-align: center;
  width: 75px;
  height: 75px;
  min-width: 75px;
  text-align: center;
  line-height: 75px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}

.picture-container .picture-product {
  max-width: 55px;
}
</style>