<template>
  <div class="row align-center justify-content-end my-4" data-toggle="buttons">
    <div class="col-12 col-md-3">
      <div
        key="startDate"
        for="startDate"
        name="startDate"
        class="d-flex align-center justify-content-center flex-column btn btn-sm btn-primary btn-simple"
      >
        <label
          for="startDate"
          class="btn btn-sm btn-primary btn-simple startDate"
          >Data Inicial</label
        >
        <input
          class="text-primary text-center mx-auto"
          type="date"
          v-model="startDate"
          name="startDate"
          id="startDate"
        />
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div
        key="endDate"
        for="endDate"
        name="endDate"
        class="d-flex align-center justify-content-center flex-column btn btn-sm btn-primary btn-simple"
      >
        <label for="endDate" class="btn btn-sm btn-primary btn-simple endDate"
          >Data Final</label
        >
        <input
          class="text-primary text-center mx-auto"
          type="date"
          v-model="endDate"
          name="endDate"
          id="endDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("pt-BR");

export default {
  name: "DatePicker",
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    this.startDate = moment().weekday(1).format("YYYY-MM-DD");
    this.endDate = moment().weekday(6).format("YYYY-MM-DD");
  },
  watch: {
    startDate(val) {
      this.$emit("receiveStartDate", val);
    },
    endDate(val) {
      this.$emit("receiveEndDate", val);
    },
  },
};
</script>

<style>
</style>