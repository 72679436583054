<template>
    <card type="plain" title="Maps">
      <LoadingSpinner :show="loading"/>
      <div id="map" class="map">
        <LMap :v-show="machines" class="map-leaflet" :zoom="zoom" :center="latLong" >
          <LTileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" attribution="&copy; <a href='https://stadiamaps.com/'>Stadia Maps</a>, &copy; <a href='https://openmaptiles.org/'>OpenMapTiles</a> &copy; <a href='http://openstreetmap.org'>OpenStreetMap</a> contributors"></LTileLayer>
          <LMarker @click="openMachine(machine.id)" v-for="(machine, index) in machines" :key="index" :lat-lng="[machine.lat, machine.long]">
            <LIcon className="icon" :iconUrl="machine.image" :iconSize="[38, 38]" />
          </LMarker>
        </LMap>
      </div>
    </card>
</template>
<script>
import { LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import LoadingSpinner from '../components/Spinner.vue';


export default {
  components: {
    LoadingSpinner,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      loading: false,
      zoom: 12,
      machines: [],
      latLong: [-8.0085141, -34.9925892]
    }
  },
  mounted() {
    this.initLoading()
  },
  methods: {
    async initLoading() {
      const storagedMapMachines = localStorage.getItem('@Snackly:mapMachines');
      if (!storagedMapMachines) {
        await this.getMachines()
      } else {
        this.machines = JSON.parse(storagedMapMachines);
      }
    },
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machine/location")
        const { machines } = data
        machines.map(machine => {
          const newMachine = {
            id: machine.ID_C,
            image: machine.IMAGE_V,
            lat: machine.V_LOCATION.V_LATITUDE,
            long: machine.V_LOCATION.V_LONGITUDE
          }
          this.machines.push(newMachine)
          this.loading = false;
        })
        localStorage.setItem('@Snackly:mapMachines', JSON.stringify(this.machines));
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
    openMachine(id) {
      this.$router.push({
        name: 'machine',
        params: { id },
        query: this.$route.query,
        hash: this.$route.hash,
      })
    },
  }
};
</script>
<style>
.map {
  height: 80vh;
}

.icon {
  border-radius: 50%;
}
</style>
