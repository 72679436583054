var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-5"},[_c('h1',{staticClass:"text-center"},[_vm._v("Editar Informações da Máquina")]),_c('LoadingSpinner',{attrs:{"show":_vm.loading}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"row g-3 justify-content-center",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 justify-content-center"},[_c('ValidationProvider',{ref:"provider",attrs:{"name":"Máquina","rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"picture",attrs:{"for":"formImage"}},[_c('span',{staticClass:"picture-container"},[_c('img',{staticClass:"picture-image",attrs:{"src":_vm.machineImage,"alt":""}})]),_c('input',{attrs:{"type":"file","name":"formImage","id":"formImage","accept":"image/*","tabindex":"0"},on:{"change":_vm.handleFileChange}})]),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Modelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputModel"}},[_vm._v("Modelo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machineForm.model),expression:"machineForm.model"}],staticClass:"form-control",attrs:{"type":"text","id":"inputModel"},domProps:{"value":(_vm.machineForm.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machineForm, "model", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputDescription"}},[_vm._v("Descrição")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.machineForm.description),expression:"machineForm.description"}],staticClass:"form-control px-2",attrs:{"maxlength":"255","id":"inputDescription","rows":"3"},domProps:{"value":(_vm.machineForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machineForm, "description", $event.target.value)}}})]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Capacidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputCapacity"}},[_vm._v("Capacidade "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machineForm.capacity),expression:"machineForm.capacity"}],staticClass:"form-control",attrs:{"type":"number","id":"inputCapacity"},domProps:{"value":(_vm.machineForm.capacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machineForm, "capacity", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Hardware","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputHardware"}},[_vm._v("Hardware")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machineForm.hardware),expression:"machineForm.hardware"}],staticClass:"form-control",attrs:{"type":"text","id":"inputHardware"},domProps:{"value":(_vm.machineForm.hardware)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machineForm, "hardware", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Data de lançamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"inputlaunch_date"}},[_vm._v("Data de Lançamento "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machineForm.launch_date),expression:"machineForm.launch_date"}],staticClass:"form-control",attrs:{"type":"Date","id":"inputlaunch_date"},domProps:{"value":(_vm.machineForm.launch_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machineForm, "launch_date", $event.target.value)}}}),_c('p',{staticClass:"fw-normal text-danger p-relative"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":"/machines"}},[_vm._v("Voltar")]),_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"}},[_vm._v("Salvar alterações")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }