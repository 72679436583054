<template>
  <div class="mt-5">
    <p class="h3 mb-3">Link usuário</p>
    <div class="box">
      <p v-if="url" class="content-link">{{ url }}</p>
      <p v-else class="content-link -disabled">Copiar Link...</p>
      <button class="copy-btn" @click="handleCopy()">
        <unicon
          class="icon-copy"
          name="copy"
          fill="#1d8cf8"
          width="22px"
        ></unicon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copy",
  props: {
    url: {
      type: String,
    },
  },
  methods: {
    handleCopy() {
      if (!this.url) return;
      this.$toast.success(`Copiado com Sucesso`);
      navigator.clipboard.writeText(this.url);
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  padding-bottom: 1.125rem;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.125rem;
  background: #1c1d2b;
  border-radius: 6.856px;
}

.content-link {
  color: #fff;
  font-weight: 600;
}

.content-link.-disabled {
  color: #969696;
}

.copy-btn {
  cursor: pointer;
  border: none;
  background: transparent;
}

.icon-copy {
  transition: 300ms ease;
}

.icon-copy:hover {
  filter: brightness(1.2);
}
</style>