<template>
  <div class="d-flex justify-content-center tab-nav-container">
    <p
      class="tab-nav col-6 text-center"
      :class="{ 'active-bar': !mode }"
      @click="toggleMode(false)"
    >
      {{ labels.first }}
    </p>
    <p
      class="tab-nav col-6 text-center"
      :class="{ 'active-bar': mode }"
      @click="toggleMode(true)"
    >
      {{ labels.second }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TabNavigation",
  props: {
    labels: Object,
    mode: Boolean,
  },
  methods: {
    toggleMode(toggle) {
      this.$emit("toggle-mode", toggle);
    },
  },
};
</script>

<style lang="css" scoped>
.tab-nav-container {
  background: #1d8cf8;
}

.tab-nav {
  padding: 12px 12px;
  margin: 0;
  margin-top: 4px;
  cursor: pointer;
  font-weight: 600;
  background: transparet;
  transition: 200ms ease-in;
}

.tab-nav.active-bar {
  background: #fff;
  color: #1d8cf8;
}
</style>