<template>
  <card>
    <LoadingSpinner :show="loading" />
    <p class="h3">Gerar conta</p>
    <base-input
      v-model="email"
      label="Email"
      placeholder="Digite o email do usuário"
    ></base-input>
    <label for="role" class="form-label"
      >Escolha uma Organização <span class="text-danger"></span
    ></label>
    <select v-model="managerCompanyId" class="form-control" id="role">
      <option
        v-for="companie in companies"
        class="bg-light"
        :key="companie.company_id"
        :value="companie.company_id"
      >
        {{ companie.fancy_name }}
      </option>
    </select>
    <label for="role" class="form-label"
      >Nível de acesso<span class="text-danger"></span
    ></label>
    <select v-model="managerRole" class="form-control" id="role">
      <option class="bg-light" :value="1">Administrador</option>
      <option class="bg-light" :value="2">Gerente</option>
      <option class="bg-light" :value="3">Inventário</option>
    </select>
    <base-button slot="footer" type="primary" @click="handleGenerateLink" fill
      >Gerar link</base-button
    >
    <copy :url="link" />
  </card>
</template>

<script>
import Copy from "@/components/Copy";
import LoadingSpinner from "@/components/Spinner.vue";

export default {
  name: "GenerateLink",
  components: {
    Copy,
    LoadingSpinner,
  },
  data() {
    return {
      managerCompanyId: "",
      managerRole: "",
      email: "",
      link: "",
      companies: [],
      loading: false,
    };
  },
  methods: {
    async handleGenerateLink() {
      try {
        if (!this.email || !this.managerRole) return;
        const link = await this.generateLink();
        this.$toast.success("Link gerado com Sucesso!");
        this.link = link;
        this.managerRole = "";
        this.email = "";
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async generateLink() {
      this.loading = true;
      const params = {
        email: this.email,
        role_id: this.managerRole,
        company_id: this.managerCompanyId,
      };
      const { data } = await this.$http.post("/generate/link", params);
      const { link } = data;
      this.loading = false;
      return link;
    },
  },
  mounted() {
    this.companies = this.$store.getters["manager/getManager"].companies;
  },
};
</script>

<style></style>