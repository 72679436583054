<template>
  <p class="badge personalized-badge text-dark mx-2" :class="isValidDate(date)">
    {{ dateFormatted(date) }}
  </p>
</template>

<script>
import moment from "moment";
moment.locale("pt-BR");

export default {
  name: "ExpirationBadge",
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  methods: {
    dateFormatted(date) {
      return moment(date).format("L");
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
  },
};
</script>

<style lang="css" scoped>
.personalized-badge {
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
</style>