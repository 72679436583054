<template>
    <div class="container" >
      <LoadingSpinner :show="loading" />
      <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div class="col-12 mb-5">
          <div class="row justify-content-center">
            <div class="col-12 mb-5 text-center">
              <img src="../../../public/img/icon.png" style="width: 220px;" alt="Mercatto Logo">
            </div>
            <div class="col-6">
              <h1>Confirmação de Conta</h1>
              <p class="mb-3 message">Por favor, verifique sua caixa de email para encontrar o código de verificação. Insira-o abaixo para concluir o processo. Obrigado!</p>
              <form @submit.prevent="handleConfirmedCode()">
                <div class="form-group">
                  <label for="exampleInputEmail1">Email</label>
                  <input type="email" v-model="email" class="form-control" id="exampleInputEmail1">
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Código de confirmação</label>
                  <input type="text" v-model="code" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                </div>
                <div class="row mt-3">
                  <div class="col-12 text-right">
                    <button type="submit" class="btn btn-primary">Confirmar</button>
                  </div>
                  <p @click="handleResendCode()">Reenviar código</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import LoadingSpinner from '@/components/Spinner.vue';
export default {
  components: {
    LoadingSpinner
  },
  data() {
    return {
      loading: false,
      code: '',
      email: '',
    }
  },
  methods: {
    async confirmSignUp() {
      const params = {
        email: this.email,
        code: this.code
      }
      await this.$http.post('manager/confirm', params);
    },
    async resendConfirmationCode() {
      const params = {
        email: this.email,
      }
      await this.$http.post('manager/resend_confirm', params);
    },
    async handleResendCode() {
      try {
        this.loading = true;
        await this.resendConfirmationCode();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
    async handleConfirmedCode() {
      try {
        this.loading = true;
        await this.confirmSignUp()
        this.$toast.success('Obrigado por confirmar o código. Agora você está pronto para fazer login')
        this.loading = false;
        this.$router.push({ name: 'login' })
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
  },
  mounted() {
    this.token = this.$router.history.current.params.token
  }
}
</script>
  
<style lang="css" scoped>
.message {
  font-weight: 500
}
</style>